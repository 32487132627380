import { SearchResource } from "../../types";
import { forwardRef, useEffect, useRef, useState } from "react";
import { searchResource, searchResourceClick } from "../../utils/apiCalls";
import { useAuthInfo } from "@propelauth/react";
import { toast } from "sonner";
import {
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandNoHeight,
} from "../../shadcn/components/command";
import { ReloadIcon } from "@radix-ui/react-icons";
import { cn } from "../../shadcn/lib/utils";
import { FileSelectorDialog, FileUploadButton } from "../FileSelectorDialog";
import { Badge } from "../../shadcn/components/badge";
import { getResourceColor } from "./resourceUtils";

const SearchResourceComponent = (props: { resource: SearchResource }) => {
  let additionalMetadataDisplay = null;
  if (props.resource.type === "document") {
    additionalMetadataDisplay = (
      <div className="text-muted-foreground">
        {props.resource.doc_type_name}
      </div>
    );
  }

  return (
    <div className="flex items-center space-x-2">
      {additionalMetadataDisplay}
      <Badge
        className={cn("text-xs", `bg-${getResourceColor(props.resource.type)}`)}
      >
        {props.resource.type}
      </Badge>
      <div className="font-semibold break-words">{props.resource.name}</div>
    </div>
  );
};

const SearchResult = (props: {
  result: SearchResource;
  queryId: string;
  onItemSelect: (result: SearchResource) => void;
  setSearchTerm: (term: string) => void;
  setSearchResults: (results: {
    results: SearchResource[];
    query_id: string;
  }) => void;
  setInputActive: (active: boolean) => void;
  cancelRef: React.MutableRefObject<boolean>;
  multiSelect?: boolean;
}) => {
  const authInfo = useAuthInfo();
  const onSelect = () => {
    props.onItemSelect(props.result);
    if (!props.multiSelect) {
      props.cancelRef.current = true;
      props.setSearchTerm("");
      props.setSearchResults({ results: [], query_id: "" });
      props.setInputActive(false);
    }

    if (props.result.id !== null && props.queryId !== "user-upload") {
      searchResourceClick(
        props.queryId,
        props.result,
        authInfo?.accessToken ?? null
      );
    }
  };

  return (
    <CommandItem key={props.result.id} onSelect={onSelect} onClick={onSelect}>
      <div className="w-full p-2 pl-6 space-y-1">
        <SearchResourceComponent resource={props.result} />
      </div>
    </CommandItem>
  );
};

export const SearchResourceBar = forwardRef<
  HTMLInputElement,
  React.HTMLAttributes<HTMLDivElement> & {
    docTypeIds: string[];
    onItemSelect: (result: SearchResource) => void;
    suggestions?: SearchResource[];
    placeholder?: string;
    autoSelect?: boolean;
    disabled?: boolean;
    hideAtlasWidget?: boolean;
    contentMatch?: boolean;
    docNameOnly?: boolean;
    multiSelect?: boolean;
    uploadButton?: boolean;
  }
>(({ className, ...props }, ref) => {
  const authInfo = useAuthInfo();
  const [searchResults, setSearchResults] = useState<{
    results: SearchResource[];
    query_id: string;
  }>({ results: [], query_id: "" });
  const [searchTerm, setSearchTerm] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [containerWidth, setContainerWidth] = useState<number | null>(null);
  const [inputActive, setInputActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const resultRef = useRef<HTMLDivElement>(null);
  const cancelRef = useRef<boolean>(false);

  const suggestionsToDisplay = props.suggestions
    ? props.suggestions.filter((suggestion) =>
        suggestion.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : null;

  const nameResults = searchResults.results.filter(
    (result) => result.match_type === "name"
  );

  const contentResults = searchResults.results.filter(
    (result) => result.match_type === "content"
  );

  const containerRef = (container: HTMLDivElement) => {
    if (container) {
      setContainerWidth(container.clientWidth);
    }
  };

  const handleSearch = async (searchQuery: string) => {
    cancelRef.current = false;
    setSearchLoading(true);
    try {
      for await (const output of searchResource(
        searchQuery,
        props.docTypeIds,
        props.contentMatch ?? true,
        props.docNameOnly ?? false,
        authInfo.accessToken ?? null
      )) {
        if (cancelRef.current) {
          break;
        }
        setSearchResults(output);
      }
    } catch (error: any) {
      console.error("There was an error running the search", error);
      toast.error("Unable to run search");
    }
    setSearchLoading(false);
  };

  const handleFileUpload = async (newDocs: SearchResource[]) => {
    setSearchResults({
      results: newDocs,
      query_id: "user-upload",
    });
  };

  useEffect(() => {
    // wait for the user to stop typing
    const timeoutId = setTimeout(() => {
      if (searchLoading) {
        cancelRef.current = true;
      }
      if (searchTerm) {
        handleSearch(searchTerm);
      } else {
        setSearchResults({ results: [], query_id: "" });
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  useEffect(() => {
    if (props.autoSelect) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
      setInputActive(false);
    }
  }, [props.autoSelect]);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        event.preventDefault();
        cancelRef.current = true;
        setSearchTerm("");
        setSearchResults({ results: [], query_id: "" });
        setInputActive(false);
      }
    };

    const onClickOutside = (e: MouseEvent) => {
      if (resultRef.current && !resultRef.current.contains(e.target as Node)) {
        cancelRef.current = true;
        setSearchTerm("");
        setSearchResults({ results: [], query_id: "" });
        setInputActive(false);
      }
    };

    document.addEventListener("keydown", handleEscape);
    document.addEventListener("mousedown", onClickOutside);

    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("mousedown", onClickOutside);
    };
  }, []);
  return (
    <div className={className} ref={containerRef}>
      <CommandNoHeight shouldFilter={false}>
        <CommandInput
          placeholder={props.placeholder ?? "Search Documents..."}
          className="text-base"
          onValueChange={setSearchTerm}
          value={searchTerm}
          onFocus={() => setInputActive(true)}
          ref={inputRef}
          disabled={props.disabled}
        >
          <div className="flex justify-end space-x-1 items-center">
            <FileSelectorDialog
              onItemSelect={props.onItemSelect}
              docTypeIds={props.docTypeIds}
              hideAtlasWidget={props.hideAtlasWidget}
            />
            {props.uploadButton && (
              <FileUploadButton
                successCallback={handleFileUpload}
                variant="ghost"
              />
            )}
          </div>
        </CommandInput>
        <CommandList
          className={cn(
            "absolute mt-11 max-h-[300px] overflow-y-auto z-10 bg-white px-2",
            searchResults.results.length > 0 &&
              "border border-4 border-gray-200 rounded-md"
          )}
          style={{
            width: containerWidth ? `${containerWidth}px` : "auto",
          }}
          ref={resultRef}
        >
          {searchLoading && (
            <div className="flex items-center justify-center text-muted-foreground text-sm py-5">
              <ReloadIcon className="animate-spin h-4 w-4 mr-2" />
              Loading results...
            </div>
          )}
          {nameResults.length > 0 && (
            <CommandGroup heading="Resource Name Matches">
              {nameResults.map((result) => (
                <SearchResult
                  key={result.id}
                  queryId={searchResults.query_id}
                  result={result}
                  onItemSelect={props.onItemSelect}
                  setSearchTerm={setSearchTerm}
                  setSearchResults={setSearchResults}
                  setInputActive={setInputActive}
                  cancelRef={cancelRef}
                  multiSelect={props.multiSelect}
                />
              ))}
            </CommandGroup>
          )}
          {contentResults.length > 0 && (
            <CommandGroup heading="Content Matches">
              {contentResults.map((result) => (
                <SearchResult
                  key={result.id}
                  queryId={searchResults.query_id}
                  result={result}
                  onItemSelect={props.onItemSelect}
                  setSearchTerm={setSearchTerm}
                  setSearchResults={setSearchResults}
                  setInputActive={setInputActive}
                  cancelRef={cancelRef}
                  multiSelect={props.multiSelect}
                />
              ))}
            </CommandGroup>
          )}
          {suggestionsToDisplay && inputActive && (
            <CommandGroup heading="Suggestions">
              {suggestionsToDisplay.length > 0 ? (
                suggestionsToDisplay.map((suggestion) => (
                  <SearchResult
                    key={suggestion.id}
                    result={suggestion}
                    queryId={searchResults.query_id}
                    onItemSelect={props.onItemSelect}
                    setSearchTerm={setSearchTerm}
                    setSearchResults={setSearchResults}
                    setInputActive={setInputActive}
                    cancelRef={cancelRef}
                    multiSelect={props.multiSelect}
                  />
                ))
              ) : (
                <div className="pl-2 text-xs italic text-gray-400">
                  None at the moment
                </div>
              )}
            </CommandGroup>
          )}
        </CommandList>
      </CommandNoHeight>
    </div>
  );
});
