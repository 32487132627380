import { useAuthInfo } from "@propelauth/react";
import { Layout } from "../../components/Layout";
import { useParams } from "react-router-dom";
import { exportAuditQuestions, getQuestions } from "../../utils/apiCalls";
import { useEffect, useMemo, useState } from "react";
import { toast } from "sonner";
import {
  AuditQuestion,
  Citation,
  Department,
  TaskStatus,
  statusToLabelMap,
} from "../../types";
import { Badge } from "../../shadcn/components/badge";
import { loadAndFormatTime } from "../../utils/format";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../shadcn/components/breadcrumb";
import { Input } from "../../shadcn/components/input";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { LoadingView } from "../../components/Loading";
import { StatusBadge } from "../../components/StatusSelector";
import {
  getFilterCounts,
  SelectFilter,
  TooltipNumber,
  TruncatedWTooltip,
} from "../../components/FilterUtils";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../shadcn/components/dropdown-menu";
import { Button } from "../../shadcn/components/button";
import { ReloadIcon } from "@radix-ui/react-icons";
import { DataTable, HeaderCell } from "../../components/Table";

const BreadcrumbNav = (props: { auditId: string }) => {
  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink href="/audit">Audits</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbLink href={`/audit/${props.auditId}`}>
            Audit Resources
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage>Questions</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );
};

const ExportAuditQuestionsButton = (props: { auditResourceId: string }) => {
  const authInfo = useAuthInfo();
  const [exportLoading, setExportLoading] = useState<boolean>(false);

  const onClickExport = async (includePolicyText: boolean) => {
    setExportLoading(true);
    const response = await exportAuditQuestions(
      props.auditResourceId,
      includePolicyText,
      authInfo.accessToken ?? null
    );
    if (!response) {
      toast.error("Failed to export requirements");
    }
    setExportLoading(false);
  };
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button className="w-24">
          Export{" "}
          {exportLoading && (
            <ReloadIcon className="w-4 h-4 ml-2 animate-spin" />
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuItem onClick={() => onClickExport(true)}>
          Export with Full Details
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => onClickExport(false)}>
          Export with only Document Name
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const AuditQuestionView = () => {
  const { auditId, auditResourceId } = useParams();
  const authInfo = useAuthInfo();
  const [questions, setQuestions] = useState<AuditQuestion[]>([]);
  const [questionsLoading, setQuestionsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [departmentFilter, setDepartmentFilter] = useState<string[]>([]);

  const columns: any[] = useMemo(
    () => [
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Section" />;
        },
        cell: ({ row }: any) => {
          return (
            <TruncatedWTooltip
              text={`${row.original.section_index}. ${row.original.section_title}`}
            />
          );
        },
        id: "section",
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Question" />;
        },
        cell: ({ row }: any) => {
          const questionText = `${row.original.question_index}. ${row.original.question}`;
          return (
            <Tooltip>
              <TooltipTrigger>
                <a
                  className="text-blue-500 underline"
                  href={`/audit/${auditId}/${auditResourceId}/question/${row.original.id}`}
                >
                  <div className="text-left">
                    {questionText.slice(0, 100)}
                    {questionText.length > 100 && "..."}
                  </div>
                </a>
              </TooltipTrigger>
              <TooltipContent>
                <div className="max-w-[600px] text-left">{questionText}</div>
              </TooltipContent>
            </Tooltip>
          );
        },
        id: "question",
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Departments" />;
        },
        cell: ({ row }: any) => {
          return (
            <TooltipNumber
              items={row.original.assignees.map(
                (assignee: Department) => assignee.name
              )}
            />
          );
        },
        id: "impacted-departments",
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Narrative" />;
        },
        cell: ({ row }: any) => {
          const narrative = row.original.narrative.trim();
          if (!narrative) {
            return null;
          }
          return (
            <div className="text-center">
              <Tooltip>
                <TooltipTrigger>
                  <Badge className="bg-gray-400">View</Badge>
                </TooltipTrigger>
                <TooltipContent>
                  <div className="max-w-[600px] text-left">{narrative}</div>
                </TooltipContent>
              </Tooltip>
            </div>
          );
        },
        id: "narrative",
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Docs" />;
        },
        cell: ({ row }: any) => {
          const uniqueDocuments = [
            ...new Map(
              row.original.citations
                .filter(Boolean)
                .map((citation: Citation) => [citation.doc_id, citation])
            ).values(),
          ] as Citation[];

          return (
            <TooltipNumber
              items={uniqueDocuments.map((citation) => citation.doc_name!)}
            />
          );
        },
        id: "docs",
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Status" />;
        },
        cell: ({ row }: any) => {
          return <StatusBadge status={row.original.status} />;
        },
        id: "status",
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Updated" />;
        },
        cell: ({ row }: any) => {
          // get max date from narrative_updated_at and status_updated_at, and each citation created_at
          const updatedAt =
            [
              row.original.narrative_updated_at,
              row.original.status_updated_at,
              ...row.original.citations.map(
                (citation: Citation) => citation.created_at
              ),
            ]
              .filter(Boolean)
              .sort(
                (a, b) => new Date(b).getTime() - new Date(a).getTime()
              )[0] || null;

          return (
            <div className="text-left">{loadAndFormatTime(updatedAt)}</div>
          );
        },
        id: "updated-at",
        accessorKey: "updated_at",
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Review" />;
        },
        cell: ({ row }: any) => {
          return (
            <>
              {row.original.review_docs_available ? (
                <a
                  href={`/audit/${auditId}/${auditResourceId}/question/${row.original.id}/review`}
                >
                  <Button variant="default">Start</Button>
                </a>
              ) : null}
            </>
          );
        },
        id: "review-docs-available",
        accessorKey: "review_docs_available",
      },
    ],
    []
  );

  const searchData = questions.filter(
    (question) =>
      question.question.toLowerCase().includes(search.toLowerCase()) ||
      search === ""
  );

  const statusCounts = getFilterCounts(
    searchData.map((question) => question.status)
  );

  const statusData = searchData.filter((question) => {
    return statusFilter.length === 0 || statusFilter.includes(question.status);
  });

  const departmentCounts = getFilterCounts(
    statusData.flatMap((question) =>
      question.assignees.map((assignee) => assignee.name)
    )
  );

  const departmentData = statusData.filter((question) => {
    return (
      departmentFilter.some((department) =>
        question.assignees.some((a) => a.name === department)
      ) || departmentFilter.length === 0
    );
  });

  useEffect(() => {
    if (auditResourceId) {
      setQuestionsLoading(true);
      getQuestions(auditResourceId, authInfo.accessToken ?? null).then(
        (questions) => {
          if (questions) {
            setQuestions(questions);
          } else {
            toast.error("Failed to fetch questions");
          }
          setQuestionsLoading(false);
        }
      );
    }
  }, [auditResourceId]);

  return (
    <Layout pageName="Audits">
      <div className="space-y-4">
        <BreadcrumbNav auditId={auditId ?? ""} />
        {questionsLoading && <LoadingView customText="Loading questions..." />}
        <DataTable
          tableWrapperClassName="overflow-y-auto h-[calc(100vh-220px)]"
          columns={columns}
          data={departmentData}
          paginationAtTop={true}
          paginationParams={{
            pageSize: 50,
            pageIndex: 0,
          }}
          totalRecordCount={departmentData.length}
          headerChildren={
            <div className="flex items-center space-x-2">
              <Input
                className="flex-grow"
                placeholder="Find questions by keywords..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <SelectFilter
                title="Status"
                filterCounts={statusCounts}
                activeFilter={statusFilter}
                setActiveFilter={setStatusFilter}
                nameFormatter={(name: string) =>
                  name !== "None"
                    ? statusToLabelMap[name as TaskStatus]
                    : "None"
                }
              />
              <SelectFilter
                title="Department"
                filterCounts={departmentCounts}
                activeFilter={departmentFilter}
                setActiveFilter={setDepartmentFilter}
              />
              <ExportAuditQuestionsButton
                auditResourceId={auditResourceId ?? ""}
              />
            </div>
          }
        />
      </div>
    </Layout>
  );
};
