import { Badge } from "../../shadcn/components/badge";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { cn } from "../../shadcn/lib/utils";
import { SearchResource, SearchResourceType } from "../../types";
import { getResourceColor } from "./resourceUtils";

const SearchResourceBadge = (props: {
  resource: SearchResource;
  setSelectedResources: React.Dispatch<React.SetStateAction<SearchResource[]>>;
  activeResourceId: string | null;
  setActiveResourceId: React.Dispatch<React.SetStateAction<string | null>>;
  selectableResourceTypes?: SearchResourceType[];
}) => {
  const active = props.activeResourceId === props.resource.id;
  const selectable =
    props.selectableResourceTypes === undefined ||
    props.selectableResourceTypes.includes(props.resource.type);
  const handleDeselect = () => {
    props.setSelectedResources((prev) =>
      prev.filter((r) => r.id !== props.resource.id)
    );
  };

  return (
    <Badge
      className={cn(
        "text-xs flex items-center justify-between",
        `bg-${getResourceColor(props.resource.type)} hover:bg-${getResourceColor(props.resource.type)}-100`,
        selectable && "hover:border-2 hover:border-black",
        active && "border-2 border-black"
      )}
      onClick={() => {
        if (selectable && !active) {
          props.setActiveResourceId(props.resource.id);
        }
      }}
    >
      <Tooltip>
        <TooltipTrigger>
          <div className="max-w-[100px] truncate ellipsis">
            {props.resource.name}
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <span>{props.resource.name}</span>
        </TooltipContent>
      </Tooltip>
      <button
        onClick={handleDeselect}
        className="ml-auto px-1 rounded-full hover:bg-gray-300 focus:outline-none"
      >
        ×
      </button>
    </Badge>
  );
};

export const ResourceSelector = (props: {
  selectedResources: SearchResource[];
  setSelectedResources: React.Dispatch<React.SetStateAction<SearchResource[]>>;
  activeResourceId: string | null;
  setActiveResourceId: React.Dispatch<React.SetStateAction<string | null>>;
  selectableResourceTypes?: SearchResourceType[];
}) => {
  return (
    <div className="flex flex-wrap gap-2">
      {props.selectedResources.map((resource) => (
        <SearchResourceBadge
          key={resource.id}
          resource={resource}
          setSelectedResources={props.setSelectedResources}
          activeResourceId={props.activeResourceId}
          setActiveResourceId={props.setActiveResourceId}
          selectableResourceTypes={props.selectableResourceTypes}
        />
      ))}
      {props.selectedResources.length > 0 && (
        <Badge
          className="text-xs flex items-center justify-between"
          onClick={() => {
            props.setSelectedResources([]);
            props.setActiveResourceId(null);
          }}
          variant="destructive"
        >
          Clear
        </Badge>
      )}
    </div>
  );
};
