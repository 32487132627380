import { SearchResourceType } from "../../types";

export const getResourceColor = (type: SearchResourceType) => {
  let color = "black";
  switch (type) {
    case "document":
      color = "blue-400";
      break;
    case "document_type":
      color = "green-400";
      break;
    case "document_requirements":
      color = "orange-400";
      break;
    case "requirement":
      color = "yellow-400";
      break;
  }

  return color;
};
