import { formatDistanceToNow, format } from "date-fns";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../shadcn/components/tooltip";
import { Citation } from "../types";

interface TimeAgoProps {
  timestamp: string;
}

const localizeDate = (rawDateString: string) => {
  let date = new Date(rawDateString);

  // convert date from utc to local
  date = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);

  return date;
};

const reverseLocalizeDate = (rawDateString: string) => {
  let date = new Date(rawDateString);

  // convert date from utc to local
  date = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  return date;
};

export const loadAndFormatDate = (rawDateString: string, localize = true) => {
  // Parse the ISO format date
  const date = localize
    ? localizeDate(rawDateString)
    : reverseLocalizeDate(rawDateString);

  // Format the date in a human-readable format
  const formattedDate = format(date, "M/d/yyyy");

  return formattedDate;
};

export const loadAndFormatTime = (rawDateString: string) => {
  const date = localizeDate(rawDateString);
  const formattedTime = format(date, "M/d/yyyy h:mm a");

  return formattedTime;
};

export const TimeAgo = ({ timestamp }: TimeAgoProps) => {
  const date = localizeDate(timestamp);
  const timeAgo = formatDistanceToNow(date, { addSuffix: true });
  return (
    <Tooltip>
      <TooltipTrigger>
        <span className="block text-right">{timeAgo}</span>
      </TooltipTrigger>
      <TooltipContent side="bottom">
        {loadAndFormatTime(timestamp)}
      </TooltipContent>
    </Tooltip>
  );
};

export const ligatureMap: Record<string, string> = {
  ﬁ: "fi",
  ﬂ: "fl",
  ﬀ: "ff",
  ﬃ: "ffi",
  ﬄ: "ffl",
  æ: "ae",
  œ: "oe",
  ĳ: "ij",
  "𝄐": "ct",
  "𝄑": "st",
  "𝄒": "sp",
  // Add other ligatures here if needed
};

export const replaceLigatures = (text: string) => {
  for (const ligature in ligatureMap) {
    const replacement = ligatureMap[ligature];
    const regex = new RegExp(ligature, "g");
    text = text.replace(regex, replacement);
  }

  return text;
};

export const processPdfString = (inputString: string) => {
  let cleanedString = inputString.replace(/\s+/g, "");
  cleanedString = replaceLigatures(cleanedString);
  // Trim any leading or trailing spaces (optional)
  cleanedString = cleanedString.trim().toLowerCase();

  return cleanedString;
};

export const escapeSpecialCharacters = (inputString: string) => {
  return inputString.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

export const citationSortOrder = (
  a: { citation: Citation } | { citations: Citation[] },
  b: { citation: Citation } | { citations: Citation[] }
) => {
  const aCitation = "citations" in a ? a.citations[0] : a.citation;
  const bCitation = "citations" in b ? b.citations[0] : b.citation;

  if (aCitation?.page !== bCitation?.page) {
    return (aCitation?.page ?? 0) - (bCitation?.page ?? 0);
  }

  // if both citations have the same start index then sort by max start index of the remaining citations
  if (aCitation?.start_index === bCitation?.start_index) {
    const aMaxStartIndex =
      "citations" in a && a.citations.length > 1
        ? Math.max(
            ...a.citations.slice(1).map((citation) => citation.start_index ?? 0)
          )
        : 0;
    const bMaxStartIndex =
      "citations" in b && b.citations.length > 1
        ? Math.max(
            ...b.citations.slice(1).map((citation) => citation.start_index ?? 0)
          )
        : 0;
    return aMaxStartIndex - bMaxStartIndex;
  }

  return (aCitation?.start_index ?? 0) - (bCitation?.start_index ?? 0);
};

export const extractTag = (message: string, tag: string): string[] => {
  const tagRegex = new RegExp(` ?<${tag}>(.*?)<\/${tag}> ?`, "g");
  const matches = message
    .match(tagRegex)
    ?.map((group) => group.split(">")[1].split("<")[0]);

  return matches ?? [];
};
