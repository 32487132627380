import { useAuthInfo } from "@propelauth/react";
import { useContext, useEffect, useRef, useState } from "react";
import { DocDiff, DocHistory, DocMetadata, DocType } from "../../types";
import {
  getDiffView,
  getDocDiffs,
  getPolicyRepoDocTypes,
} from "../../utils/apiCalls";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { SearchResourceBar } from "../../components/Resource/SearchResource";
import { BaseDocViewerControls, DocViewer } from "../../components/DocViewer";
import { LoadingView } from "../../components/Loading";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { Button } from "../../shadcn/components/button";
import { InputIcon, Pencil2Icon } from "@radix-ui/react-icons";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import { Switch } from "../../shadcn/components/switch";
import { Label } from "../../shadcn/components/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../shadcn/components/select";
import { DocHistoryTitle } from "./DocHistoryView";
import ReactDiffViewer from "react-diff-viewer-continued";
import { DocValidationView } from "./DocValidationView";

const DiffControl = (props: {
  diffs: DocDiff[];
  baseId: string;
  compareId: string;
  diffToggle: boolean;
  setDiffToggle: React.Dispatch<React.SetStateAction<boolean>>;
  activeCompareDocId: string;
  setActiveCompareDocId: React.Dispatch<React.SetStateAction<string>>;
  diffsLoading: boolean;
  compareDocs: DocHistory[];
}) => {
  const { setPageNumber } = useContext(DocViewerContext);
  return (
    <div className="space-x-2 flex items-center">
      <div className="flex items-center space-x-2">
        <Switch
          id="diff-toggle"
          checked={props.diffToggle}
          onCheckedChange={props.setDiffToggle}
        />
        <Label htmlFor="diff-toggle">Diff</Label>
      </div>
      <Select
        value={props.activeCompareDocId}
        onValueChange={(compareDocId) => {
          props.setActiveCompareDocId(compareDocId);
        }}
        disabled={!props.diffToggle}
      >
        <SelectTrigger className="w-full bg-white">
          <SelectValue placeholder="Select document to compare.." />
        </SelectTrigger>
        <SelectContent>
          {props.compareDocs.map((compareDoc) => (
            <SelectItem value={compareDoc.id} key={compareDoc.id}>
              <DocHistoryTitle docHistory={compareDoc} />
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      {props.diffs.length > 0 ? (
        <Select
          onValueChange={(pageNumber) => {
            setPageNumber(parseInt(pageNumber));
          }}
          disabled={props.diffsLoading || !props.diffToggle}
        >
          <SelectTrigger className="w-full bg-white">
            <div className="text-center">
              <SelectValue placeholder="Select page with changes to jump to it..." />
            </div>
          </SelectTrigger>
          <SelectContent>
            {props.diffs.map((diff) => (
              <SelectItem value={diff.page.toString()} key={diff.page}>
                Page {diff.page}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      ) : (
        <div className="text-sm text-gray-500 w-[200px]">No Differences</div>
      )}
    </div>
  );
};

const DiffViewer = (props: {
  docId: string;
  oldItem: DocHistory;
  newItem: DocHistory;
}) => {
  const authInfo = useAuthInfo();
  const { pageNumber } = useContext(DocViewerContext);
  const [loading, setLoading] = useState(false);
  const [diffPages, setDiffPages] = useState<{ base: string; compare: string }>(
    { base: "", compare: "" }
  );

  useEffect(() => {
    setLoading(true);
    getDiffView(
      props.docId,
      props.newItem.id,
      props.oldItem.id,
      pageNumber,
      authInfo.accessToken ?? null
    ).then((diffPages) => {
      if (diffPages !== null) {
        setDiffPages(diffPages);
      } else {
        toast.error("Failed to load diff pages");
      }
      setLoading(false);
    });
  }, [pageNumber, props.docId, props.oldItem.id, props.newItem.id]);

  return (
    <div>
      {loading && (
        <LoadingView customText="Fetching Page Differences... One Moment!" />
      )}
      {!loading && (
        <ReactDiffViewer
          oldValue={diffPages.base}
          newValue={diffPages.compare}
          leftTitle={<DocHistoryTitle docHistory={props.oldItem} />}
          rightTitle={<DocHistoryTitle docHistory={props.newItem} />}
          splitView={true}
          hideLineNumbers={true}
          showDiffOnly={false}
        />
      )}
    </div>
  );
};

const DocControls = (props: {
  docId: string;
  docTypes: DocType[];
  docMetadata: DocMetadata | null;
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center gap-4 w-full overflow-x-auto">
      <div className="flex-grow min-w-[300px]">
        <SearchResourceBar
          docTypeIds={props.docTypes.map((docType) => docType.id)}
          onItemSelect={(item) => {
            navigate(`/doc-chat?docId=${item.id}`);
          }}
          hideAtlasWidget={true}
          docNameOnly={true}
          className="relative"
        />
      </div>
      {props.docMetadata?.editable_doc_id && (
        <Tooltip>
          <TooltipTrigger>
            <Button
              variant="outline"
              size="icon"
              onClick={() => {
                navigate(
                  `/policy-repo/doc-view/${props.docMetadata?.editable_doc_id}`
                );
              }}
            >
              <Pencil2Icon className="w-4 h-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>Source Document</TooltipContent>
        </Tooltip>
      )}
      {props.docMetadata?.doc_source && (
        <Tooltip>
          <TooltipTrigger>
            <Button
              variant="outline"
              size="icon"
              onClick={() => {
                if (props.docMetadata?.doc_source) {
                  window.open(props.docMetadata?.doc_source, "_blank");
                }
              }}
            >
              <InputIcon className="w-4 h-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>Edit Document in Sharepoint</TooltipContent>
        </Tooltip>
      )}
      <DocValidationView docId={props.docId} />
      <BaseDocViewerControls hideAtlasWidget={true} />
    </div>
  );
};

export const DocView = (props: {
  docId: string;
  docMetadata: DocMetadata;
  activeHistory?: DocHistory;
  comparisonDocs: DocHistory[];
}) => {
  const authInfo = useAuthInfo();
  const { docToView, setDocToView, setScrollBoxRef } =
    useContext(DocViewerContext);
  const [docTypes, setDocTypes] = useState<DocType[]>([]);
  const [diffToggle, setDiffToggle] = useState(false);
  const [diffsLoading, setDiffsLoading] = useState(false);
  const [activeCompareDocId, setActiveCompareDocId] = useState<string>(
    props.comparisonDocs?.[0]?.id ?? ""
  );
  const scrollBoxRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (scrollBoxRef.current) {
      setScrollBoxRef(scrollBoxRef.current);
    }
  }, [scrollBoxRef, scrollBoxRef.current]);
  const [diffs, setDiffs] = useState<{
    diffs: DocDiff[];
    baseId: string;
    compareId: string;
  }>();

  const activeComparisonDoc = props.comparisonDocs?.find(
    (doc) => doc.id === activeCompareDocId
  );

  useEffect(() => {
    getPolicyRepoDocTypes(authInfo.accessToken ?? null).then((docTypes) => {
      if (docTypes !== null) {
        setDocTypes(docTypes);
      } else {
        toast.error("Failed to load document types");
      }
    });
  }, []);

  useEffect(() => {
    if (
      props.docId &&
      (docToView?.docId !== props.docId ||
        docToView?.historyId !== props.activeHistory)
    ) {
      setDocToView({
        docId: props.docId,
        historyId: props.activeHistory?.id ?? "",
      });
    }
  }, [props.docId, props.activeHistory]);

  useEffect(() => {
    if (!diffToggle) {
      setDiffs(undefined);
    }
  }, [diffToggle]);

  useEffect(() => {
    if (props.activeHistory && activeCompareDocId && diffToggle) {
      setDiffsLoading(true);
      getDocDiffs(
        props.docId,
        props.activeHistory.id,
        activeCompareDocId,
        authInfo.accessToken ?? null
      ).then((diffs) => {
        if (diffs !== null) {
          setDiffs({
            diffs: diffs,
            baseId: props.activeHistory!.id,
            compareId: activeCompareDocId,
          });
        } else {
          toast.error("Failed to load diffs");
        }
        setDiffsLoading(false);
      });
    }
  }, [props.activeHistory?.id, activeCompareDocId, diffToggle]);

  return (
    <div className="space-y-4 mt-2 pl-4">
      <DocControls
        docTypes={docTypes}
        docMetadata={props.docMetadata}
        docId={props.docId}
      />
      {props.activeHistory && props.comparisonDocs && (
        <DiffControl
          diffs={diffs?.diffs ?? []}
          baseId={diffs?.baseId ?? ""}
          compareId={diffs?.compareId ?? ""}
          diffToggle={diffToggle}
          setDiffToggle={setDiffToggle}
          activeCompareDocId={activeCompareDocId}
          setActiveCompareDocId={setActiveCompareDocId}
          diffsLoading={diffsLoading}
          compareDocs={props.comparisonDocs}
        />
      )}
      {diffsLoading && (
        <LoadingView
          customText={
            "Calculating Differences between Documents... One Moment!"
          }
        />
      )}
      {docToView && (
        <div
          className="h-[calc(100vh-275px)] overflow-y-auto"
          ref={scrollBoxRef}
        >
          {activeComparisonDoc &&
          props.activeHistory &&
          !diffsLoading &&
          diffs &&
          diffs.baseId === props.activeHistory.id &&
          diffs.compareId === activeCompareDocId ? (
            <DiffViewer
              docId={props.docId}
              oldItem={activeComparisonDoc}
              newItem={props.activeHistory}
            />
          ) : (
            <DocViewer />
          )}
        </div>
      )}
    </div>
  );
};
