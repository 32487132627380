import { useAuthInfo } from "@propelauth/react";
import { Layout } from "../../components/Layout";
import { useParams } from "react-router-dom";
import {
  getAuditResource,
  updateAuditResourceDocs,
  updateAuditResourceStatus,
} from "../../utils/apiCalls";
import { useContext, useEffect, useState } from "react";
import { toast } from "sonner";
import { AuditResource, SearchResource, TaskStatus } from "../../types";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../shadcn/components/breadcrumb";
import { StandardStatusSelector } from "../../components/StatusSelector";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../shadcn/components/resizable";
import { DocViewerCitation } from "../../components/DocViewer";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import { LoadingView } from "../../components/Loading";
import { cn } from "../../shadcn/lib/utils";
import { FileUploadButton } from "../../components/FileSelectorDialog";

const BreadcrumbNav = (props: { auditId: string; resourceName: string }) => {
  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink href="/audit">Audits</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbLink href={`/audit/${props.auditId}`}>
            Audit Resources
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage>{props.resourceName}</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );
};

export const AuditUploadView = () => {
  const { auditId, auditResourceId } = useParams();
  const authInfo = useAuthInfo();
  const [auditResource, setAuditResource] = useState<AuditResource | null>(
    null
  );
  const [auditResourceLoading, setAuditResourceLoading] = useState(true);
  const { docToView, setDocToView } = useContext(DocViewerContext);
  const [docs, setDocs] = useState<{ doc_id: string; doc_name: string }[]>([]);

  useEffect(() => {
    if (auditResourceId) {
      setAuditResourceLoading(true);
      getAuditResource(auditResourceId, authInfo.accessToken ?? null).then(
        (resource) => {
          if (resource) {
            setAuditResource(resource);
            setDocs(resource.resource_value["docs"] ?? []);
          } else {
            toast.error("Failed to fetch audit resource");
          }
          setAuditResourceLoading(false);
        }
      );
    }
  }, [auditResourceId]);

  const handleFileUpload = async (newDocs: SearchResource[]) => {
    setDocs([
      ...docs,
      ...newDocs.map((doc) => ({ doc_id: doc.id, doc_name: doc.name })),
    ]);
    await updateAuditResourceDocs(
      auditResourceId ?? "",
      newDocs.map((doc) => doc.id),
      authInfo.accessToken ?? null
    );
  };

  const handleStatusChange = async (status: string) => {
    setAuditResource((prev) => {
      if (prev) {
        return { ...prev, status: status as TaskStatus };
      }
      return prev;
    });
    const response = await updateAuditResourceStatus(
      auditResourceId ?? "",
      status as TaskStatus,
      authInfo.accessToken ?? null
    );
    if (response) {
      toast.success("Audit resource status updated successfully");
    } else {
      toast.error("Failed to update audit resource status");
    }
  };

  return (
    <Layout pageName="Audits">
      <BreadcrumbNav
        auditId={auditId ?? ""}
        resourceName={auditResource?.name ?? ""}
      />
      <div className="flex items-center justify-between">
        <FileUploadButton successCallback={handleFileUpload} />
        {auditResource && (
          <StandardStatusSelector
            status={auditResource.status}
            handleStatusChange={handleStatusChange}
          />
        )}
      </div>
      {auditResourceLoading && <LoadingView />}
      <ResizablePanelGroup direction="horizontal">
        <ResizablePanel
          defaultSize={50}
          minSize={50}
          maxSize={60}
          id="resource-panel"
          order={2}
          className="space-y-4 pr-4"
        >
          {auditResource && (
            <div className="w-[100%] space-y-3 overflow-y-auto h-[calc(100vh-255px)]">
              {docs.map((doc: { doc_id: string; doc_name: string }) => (
                <div
                  key={doc.doc_id}
                  className={cn(
                    "w-full p-5 rounded-md",
                    docToView?.docId === doc.doc_id
                      ? "bg-gray-200"
                      : "bg-white hover:bg-gray-200 cursor-pointer"
                  )}
                  onClick={() => {
                    setDocToView({ docId: doc.doc_id });
                  }}
                >
                  {doc.doc_name}
                </div>
              ))}
              {docs.length === 0 && (
                <div className="text-center text-gray-500">
                  No files uploaded
                </div>
              )}
            </div>
          )}
        </ResizablePanel>
        <ResizableHandle withHandle className="mx-4" />
        <ResizablePanel
          defaultSize={50}
          minSize={40}
          maxSize={50}
          id="doc-view-panel"
          order={3}
        >
          {docToView?.docId && (
            <DocViewerCitation
              docId={docToView.docId}
              className="h-[calc(100vh-295px)]"
              hideAtlasWidget={false}
            />
          )}
        </ResizablePanel>
      </ResizablePanelGroup>
    </Layout>
  );
};
