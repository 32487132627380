import React, { ReactNode, useEffect, useRef, useState } from "react";
import { PDFDocumentProxy, PDFPageProxy, TextLayer } from "pdfjs-dist";
import "./style.css";
import { usePdf } from "./PdfContext";
import { usePdfHighlighter } from "./PdfHighlighter";
import { HighlightLayer } from "./PdfHighlighter/HighlightLayer";

interface PageProps {
  pdfDocument: PDFDocumentProxy;
  pageNumber: number;
  scale: number;
  pageHeight: number;
  pageTop: number;
  pageLoadingContent?: ReactNode;
  onHighlightLayerRender?: (pageNum: number) => void;
}

const TestPage: React.FC<PageProps> = React.memo(
  ({
    pdfDocument,
    pageNumber,
    pageHeight,
    pageTop,
    pageLoadingContent = null,
    onHighlightLayerRender = () => {},
  }) => {
    const {
      pageRefs,
      scale,
      canvasRefs,
      textLayerRefs,
      highlightLayerRefs,
      citations,
    } = usePdf();
    const {
      onHighlightLayerRender: PdfHighlighterOnHighlightLayerRender,
      onTextLayerRender: PdfHighlighterOnTextLayerRender,
    } = usePdfHighlighter();
    const [isTextLayerLoading, setIsTextLayerLoading] = useState<boolean>(true);
    const [isCanvasLoading, setIsCanvasLoading] = useState<boolean>(false);
    const canvasContainerRef = useRef<HTMLDivElement | null>(null);
    const textLayerRef = useRef<HTMLDivElement | null>(null);
    const citationLayerRef = useRef<HTMLDivElement | null>(null);
    const highlightLayerRef = useRef<HTMLCanvasElement | null>(null);

    useEffect(() => {
      const renderCanvas = async () => {
        const canvas = document.createElement("canvas");
        canvas.className = "PDF__canvas__layer page__${pageNumber}";

        const page: PDFPageProxy = await pdfDocument.getPage(pageNumber);
        const viewport = page.getViewport({ scale });

        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const renderContext = {
          canvasContext: canvas.getContext("2d")!,
          viewport,
        };

        await page.render(renderContext).promise;

        if (
          canvas &&
          canvasContainerRef.current &&
          canvas.parentElement !== canvasContainerRef.current
        ) {
          canvasContainerRef.current.innerHTML = "";
          canvasContainerRef.current.appendChild(canvas);
          const highlightLayer = highlightLayerRef.current;
          if (highlightLayer) {
            highlightLayer.width = canvas.width;
            highlightLayer.height = canvas.height;
            highlightLayerRefs.current?.set(pageNumber, highlightLayer);
          }
          onHighlightLayerRender(pageNumber);
          // PdfHighlighterOnHighlightLayerRender(pageNumber);
        }
        // Store the rendered canvas in the cache
        canvasRefs.current?.set(pageNumber, canvas);
      };
      renderCanvas();
    }, [pdfDocument, pageNumber, scale]); // Depend only on pdfDocument, pageNumber, and scale for canvas rendering

    useEffect(() => {
      const applyTextLayer = async () => {
        const textLayerDiv = textLayerRef.current;
        const page: PDFPageProxy = await pdfDocument.getPage(pageNumber);
        const viewport = page.getViewport({ scale });
        if (textLayerDiv) {
          textLayerDiv.style.width = `${viewport.width}px`;
          textLayerDiv.style.height = `${viewport.height}px`;
          textLayerDiv.style.setProperty("--scale-factor", scale.toString());

          const textContent = await page.getTextContent();
          const textLayer = new TextLayer({
            container: textLayerDiv,
            textContentSource: textContent,
            viewport,
          });
          textLayerDiv.innerHTML = "";
          await textLayer.render();
          textLayerRefs?.current?.set(pageNumber, textLayerDiv);
          PdfHighlighterOnTextLayerRender(pageNumber);
          setIsTextLayerLoading(false);
        }
      };
      setIsTextLayerLoading(true);
      applyTextLayer();
    }, [pdfDocument, pageNumber, scale]); // Depend on pdfDocument, pageNumber, and scale for text layer rendering

    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className={`PDF__page__container`}
          style={{ position: "relative", background: "white" }}
          ref={(ref) => {
            if (ref) {
              pageRefs.current?.set(pageNumber, ref);
            }
          }}
          data-page-top={pageTop}
        >
          <div
            className={`PDF__canvas__layer page__${pageNumber}`}
            ref={canvasContainerRef}
          />
          <div ref={textLayerRef} className="textLayer" />
          {!(isTextLayerLoading || isCanvasLoading) && (
            <HighlightLayer page={pageNumber} />
          )}
          <canvas
            className={`PDF__highlight__layer page__${pageNumber}`}
            ref={highlightLayerRef}
          />
          {(isTextLayerLoading || isCanvasLoading) && (
            <div
              style={{
                position: "absolute",
                top: "0",
                zIndex: 2000,
                width: "100%",
                height: `${pageHeight}px`,
                background: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {pageLoadingContent ? pageLoadingContent : <>Loading...</>}
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default TestPage;
