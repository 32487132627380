import { CalendarIcon } from "@radix-ui/react-icons";
import { Badge } from "../shadcn/components/badge";
import { Button } from "../shadcn/components/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../shadcn/components/popover";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../shadcn/components/tooltip";
import { cn } from "../shadcn/lib/utils";
import { MultiSelectControl } from "./MultiSelectControl";
import { format } from "date-fns";
import { Calendar } from "../shadcn/components/calendar";

export const SelectFilter = (props: {
  title: string;
  filterCounts: Record<string, number>;
  activeFilter: string[];
  setActiveFilter: React.Dispatch<React.SetStateAction<string[]>>;
  nameFormatter?: (name: string) => string;
  disableClear?: boolean;
}) => {
  return (
    <MultiSelectControl
      title={props.title}
      items={Object.entries(props.filterCounts)
        .sort((a, b) => b[1] - a[1])
        .map(([value, count]) => ({
          id: value,
          name: props.nameFormatter ? props.nameFormatter(value) : value,
          count: count,
        }))}
      selectedItems={props.activeFilter.map((value) => ({
        id: value,
        name: props.nameFormatter ? props.nameFormatter(value) : value,
      }))}
      selectItem={(item, isSelected) => {
        if (isSelected) {
          // find doc type
          props.setActiveFilter((prev) => [...prev, item.id]);
        } else {
          props.setActiveFilter((prev) =>
            prev.filter((value) => value !== item.id)
          );
        }
      }}
      clearSelectedItems={() => {
        if (!props.disableClear) {
          props.setActiveFilter([]);
        }
      }}
      selectAll={() => {
        props.setActiveFilter(Object.keys(props.filterCounts));
      }}
      selectItemOnly={(item) => {
        props.setActiveFilter([item.id]);
      }}
    />
  );
};

export const getFilterCounts = (filterValuesAll: string[]) => {
  const filterCounts: Record<string, number> = filterValuesAll.reduce(
    (acc, value) => {
      acc[value] = (acc[value] || 0) + 1;
      return acc;
    },
    {} as Record<string, number>
  );
  return filterCounts;
};

export const TooltipNumber = (props: { items: string[] }) => {
  if (props.items.length === 0) {
    return null;
  }
  return (
    <div className="text-center">
      <Tooltip>
        <TooltipTrigger>
          <Badge className="bg-gray-400">{props.items.length}</Badge>
        </TooltipTrigger>
        <TooltipContent>
          <div className="space-y-1 max-w-[600px]">
            {props.items.map((item) => (
              <div key={item} className="text-left">
                {item}
              </div>
            ))}
          </div>
        </TooltipContent>
      </Tooltip>
    </div>
  );
};

export const TruncatedWTooltip = (props: { text: string }) => {
  return (
    <Tooltip>
      <TooltipTrigger>
        <div className="text-left">
          {props.text.slice(0, 50)}
          {props.text.length > 50 && "..."}
        </div>
      </TooltipTrigger>
      <TooltipContent>
        <div className="max-w-[600px]">{props.text}</div>
      </TooltipContent>
    </Tooltip>
  );
};

export const DateSelector = (props: {
  date: Date | null;
  setDate: (date: Date | null) => void;
  maxDate?: Date;
  disabled?: boolean;
  placeholder?: string;
}) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          disabled={props.disabled}
          variant={"outline"}
          className={cn(
            "w-[240px] justify-start text-left font-normal",
            !props.date && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {props.date
            ? format(props.date, "PPP")
            : props.placeholder
              ? props.placeholder
              : "Select a Date"}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="flex w-auto flex-col space-y-2 p-2"
      >
        <div className="rounded-md border">
          <Calendar
            mode="single"
            selected={props.date ?? undefined}
            onSelect={(date) => props.setDate(date ?? null)}
            toDate={props.maxDate}
            defaultMonth={props.date ?? new Date()}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};
