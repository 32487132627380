import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  Table as ReactTable,
  PaginationState,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../shadcn/components/table";
import { Button } from "../shadcn/components/button";
import { useEffect, useState } from "react";
import { ArrowUpDown } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../shadcn/components/select";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from "@radix-ui/react-icons";
import { cn } from "../shadcn/lib/utils";

export const HeaderCell = (props: { column: any; columnName: string }) => {
  return (
    <Button
      variant="ghost"
      onClick={() =>
        props.column.toggleSorting(props.column.getIsSorted() === "asc")
      }
    >
      {props.columnName}
      {props.column.getCanSort() && <ArrowUpDown className="ml-2 h-4 w-4" />}
    </Button>
  );
};

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  paginationAtTop?: boolean;
  paginationParams?: PaginationState;
  headerChildren?: React.ReactNode;
  tableWrapperClassName?: string;
  totalRecordCount: number;
  setPaginationParams?: (
    pagination: PaginationState,
    sorting: SortingState
  ) => void;
  tableCellClassName?: string;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  paginationAtTop,
  headerChildren,
  paginationParams,
  tableWrapperClassName,
  totalRecordCount,
  setPaginationParams,
  tableCellClassName,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      pagination: {
        pageSize: paginationParams?.pageSize || 10,
        pageIndex: paginationParams?.pageIndex || 0,
      },
    },
    state: {
      sorting,
    },
    manualPagination: setPaginationParams !== undefined,
    pageCount: setPaginationParams
      ? Math.ceil(totalRecordCount / (paginationParams?.pageSize || 10))
      : undefined,
  });

  const paginationObject = (
    <div className="flex justify-between py-4">
      <DataTablePagination table={table} totalRecords={totalRecordCount} />
      {headerChildren}
    </div>
  );

  useEffect(() => {
    if (setPaginationParams) {
      setPaginationParams(table.getState().pagination, sorting);
    }
  }, [table.getState().pagination, sorting]);

  return (
    <div className="space-y-4">
      {paginationAtTop ? paginationObject : null}
      <div className={cn("rounded-md border bg-white", tableWrapperClassName)}>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className={cn(tableCellClassName)}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {paginationAtTop ? null : paginationObject}
    </div>
  );
}

interface DataTablePaginationProps<TData> {
  table: ReactTable<TData>;
  totalRecords: number;
}

function DataTablePagination<TData>({
  table,
  totalRecords,
}: DataTablePaginationProps<TData>) {
  return (
    <div className="flex items-center space-x-2">
      <div className="flex items-center space-x-2">
        <p className="text-sm font-medium">Rows per Page</p>
        <Select
          value={`${table.getState().pagination.pageSize}`}
          onValueChange={(value) => {
            table.setPageSize(Number(value));
          }}
        >
          <SelectTrigger className="h-8 w-[70px]">
            <SelectValue placeholder={table.getState().pagination.pageSize} />
          </SelectTrigger>
          <SelectContent side="top">
            {[10, 20, 50, 100].map((pageSize) => (
              <SelectItem key={pageSize} value={`${pageSize}`}>
                <div className="text-sm">{pageSize}</div>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div className="flex w-[100px] items-center justify-center text-sm font-medium">
        Page {table.getState().pagination.pageIndex + 1} of{" "}
        {table.getPageCount()}
      </div>
      <div className="flex items-center space-x-1">
        <Button
          variant="outline"
          className="hidden h-8 w-8 p-0 lg:flex"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          <span className="sr-only">Go to first page</span>
          <DoubleArrowLeftIcon className="h-4 w-4" />
        </Button>
        <Button
          variant="outline"
          className="h-8 w-8 p-0"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <span className="sr-only">Go to previous page</span>
          <ChevronLeftIcon className="h-4 w-4" />
        </Button>
        <Button
          variant="outline"
          className="h-8 w-8 p-0"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <span className="sr-only">Go to next page</span>
          <ChevronRightIcon className="h-4 w-4" />
        </Button>
        <Button
          variant="outline"
          className="hidden h-8 w-8 p-0 lg:flex"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          <span className="sr-only">Go to last page</span>
          <DoubleArrowRightIcon className="h-4 w-4" />
        </Button>
      </div>
      <div className="text-gray-500 text-sm">{totalRecords} records</div>
    </div>
  );
}
