import React, { createContext, useContext, useState, RefObject } from "react";

interface ModalContextProps {
  activeModalRef: RefObject<HTMLDivElement> | null;
  setActiveModalRef: (ref: RefObject<HTMLDivElement> | null) => void;
}

const ModalContext = createContext<ModalContextProps>({
  activeModalRef: null,
  setActiveModalRef: () => {},
});

export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [activeModalRef, setActiveModalRef] =
    useState<RefObject<HTMLDivElement> | null>(null);

  return (
    <ModalContext.Provider value={{ activeModalRef, setActiveModalRef }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  return useContext(ModalContext);
};
