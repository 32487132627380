import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { DashboardView as CcahDashboardView } from "./DashboardView";
import { DashboardView as SfhpDashboardView } from "./DEMO_sfhp/DashboardView";

export const DashboardRouter = () => {
  const { org } = useContext(UserContext);

  if (org === "San Francisco Health Plan") {
    return <SfhpDashboardView />;
  }

  return <CcahDashboardView />;
};
