import { ReactNode, useContext, useEffect, useState } from "react";
import { Layout } from "../../components/Layout";
import {
  Attestation,
  PolicyRepoDoc,
  PolicyRepoOverview,
  RecentlyViewedDoc,
} from "../../types";
import {
  getPolicyRepoOverview,
  getRecentlyViewedDocs,
  userAttest,
} from "../../utils/apiCalls";
import { useAuthInfo } from "@propelauth/react";
import { toast } from "sonner";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../shadcn/components/card";
import { TimeAgo } from "../../utils/format";
import { SearchResourceBar } from "../../components/Resource/SearchResource";
import { UserContext } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { Badge } from "../../shadcn/components/badge";
import { cn } from "../../shadcn/lib/utils";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "../../shadcn/components/dialog";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../shadcn/components/resizable";
import { DocViewerCitation } from "../../components/DocViewer";
import { Button } from "../../shadcn/components/button";
import { ReloadIcon } from "@radix-ui/react-icons";
import { LoadingView } from "../../components/Loading";

const DocItem = (props: {
  docId: string;
  docName: string;
  docDate: string;
}) => {
  return (
    <div className="text-left gap-4 p-3 w-full rounded-md shadow-md cursor-pointer hover:bg-gray-100 bg-white">
      <a href={`/policy-repo/doc-view/${props.docId}`}>
        <div className="space-y-2">
          <div>{props.docName}</div>
          <div className="text-gray-500 text-xs">
            <TimeAgo timestamp={props.docDate} />
          </div>
        </div>
      </a>
    </div>
  );
};

const StandardDocsCard = (props: { docs: PolicyRepoDoc[] }) => {
  return (
    <>
      {props.docs.map((doc, i) => (
        <DocItem
          key={i}
          docId={doc.doc_id}
          docName={doc.name}
          docDate={doc.relevant_date}
        />
      ))}
      {props.docs.length === 0 && (
        <div className="flex justify-center items-center h-full">
          <div className="text-gray-500">No documents</div>
        </div>
      )}
    </>
  );
};

const UserAttestationDialog = (props: {
  open: boolean;
  setOpen: (open: boolean) => void;
  attestation: Attestation;
  setPolicyRepoOverview: React.Dispatch<
    React.SetStateAction<PolicyRepoOverview | null>
  >;
}) => {
  const authInfo = useAuthInfo();
  const [attestLoading, setAttestLoading] = useState(false);
  const onAttest = async () => {
    setAttestLoading(true);
    const response = await userAttest(
      props.attestation.flow_instance_id,
      authInfo.accessToken ?? null
    );
    setAttestLoading(false);
    if (response !== null) {
      toast.success("Attestation submitted");
      props.setPolicyRepoOverview((prev) => {
        if (prev) {
          return {
            ...prev,
            attestations: prev.attestations.map((attestation) =>
              attestation.id === props.attestation.id
                ? { ...attestation, attested: true }
                : attestation
            ),
          };
        }
        return null;
      });
      props.setOpen(false);
    } else {
      toast.error("Failed to submit attestation");
    }
  };

  return (
    <Dialog open={props.open} onOpenChange={props.setOpen}>
      <DialogContent className="max-w-[90%] h-[90%]">
        <DialogTitle>Attestation</DialogTitle>
        <ResizablePanelGroup direction="horizontal">
          <ResizablePanel
            defaultSize={30}
            minSize={25}
            maxSize={50}
            id="item-navigation-panel"
            order={2}
          >
            <div className="space-y-2">
              <div>
                I confirm that I have reviewed the document and acknowledge its
                contents.
              </div>
              <Button
                variant="default"
                onClick={onAttest}
                disabled={attestLoading}
              >
                Attest
                {attestLoading && (
                  <ReloadIcon className="h-4 w-4 ml-2 animate-spin" />
                )}
              </Button>
            </div>
          </ResizablePanel>
          <ResizableHandle withHandle />
          <ResizablePanel
            defaultSize={70}
            minSize={50}
            maxSize={70}
            id="doc-panel"
            order={3}
          >
            <DocViewerCitation
              docId={props.attestation.doc_id}
              hideAtlasWidget={true}
            />
          </ResizablePanel>
        </ResizablePanelGroup>
      </DialogContent>
    </Dialog>
  );
};

const AttestationsCard = (props: {
  attestations: Attestation[];
  setPolicyRepoOverview: React.Dispatch<
    React.SetStateAction<PolicyRepoOverview | null>
  >;
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedAttestation, setSelectedAttestation] =
    useState<Attestation | null>(null);
  return (
    <>
      {selectedAttestation && (
        <UserAttestationDialog
          open={dialogOpen}
          setOpen={setDialogOpen}
          attestation={selectedAttestation}
          setPolicyRepoOverview={props.setPolicyRepoOverview}
        />
      )}
      {props.attestations.map((attestation) => (
        <div
          className={cn(
            "text-left gap-4 p-3 w-full rounded-md shadow-md bg-white",
            !attestation.attested && "cursor-pointer hover:bg-gray-100"
          )}
          onClick={() => {
            if (!attestation.attested) {
              setSelectedAttestation(attestation);
              setDialogOpen(true);
            }
          }}
        >
          <div className="space-y-2">
            <div>{attestation.doc_name}</div>
            <Badge variant={attestation.attested ? "default" : "destructive"}>
              {attestation.attested ? "Complete" : "Not Complete"}
            </Badge>
            <div className="text-gray-500 text-xs">
              <TimeAgo timestamp={attestation.updated_at} />
            </div>
          </div>
        </div>
      ))}
      {props.attestations.length === 0 && (
        <div className="flex justify-center items-center h-full">
          <div className="text-gray-500">No attestations</div>
        </div>
      )}
    </>
  );
};

const ReviewCard = (props: { title: string; children: ReactNode }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{props.title}</CardTitle>
      </CardHeader>
      <CardContent className="h-[300px] overflow-y-auto space-y-4">
        {props.children}
      </CardContent>
    </Card>
  );
};

const DocExplorer = () => {
  const authInfo = useAuthInfo();
  const navigate = useNavigate();
  const { policyRepoDocTypes } = useContext(UserContext);
  const policyRepoDocTypeIds = policyRepoDocTypes.map((docType) => docType.id);
  const [recentlyViewedDocs, setRecentlyViewedDocs] = useState<
    RecentlyViewedDoc[] | null
  >(null);

  useEffect(() => {
    if (policyRepoDocTypeIds.length > 0) {
      getRecentlyViewedDocs(
        policyRepoDocTypeIds,
        authInfo.accessToken ?? null
      ).then((response) => {
        if (response !== null) {
          setRecentlyViewedDocs(response);
        } else {
          toast.error("Failed to fetch recently viewed docs");
        }
      });
    }
  }, []);

  return (
    <div className="space-y-4">
      <SearchResourceBar
        docTypeIds={policyRepoDocTypeIds}
        onItemSelect={(searchResource) => {
          navigate(`/policy-repo/doc-view/${searchResource.id}`);
        }}
        hideAtlasWidget={true}
        className="relative"
        docNameOnly={true}
      />
      {recentlyViewedDocs && (
        <div className="overflow-y-auto space-y-2">
          <div className="font-semibold text-lg">Recently Viewed</div>
          {recentlyViewedDocs.map((doc) => (
            <DocItem
              key={doc.doc_id}
              docId={doc.doc_id}
              docName={doc.name}
              docDate={doc.date}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export const PolicyRepositoryOverviewView = () => {
  const authInfo = useAuthInfo();
  const [overviewLoading, setOverviewLoading] = useState(false);
  const [policyRepoOverview, setPolicyRepoOverview] =
    useState<PolicyRepoOverview | null>(null);

  useEffect(() => {
    setOverviewLoading(true);
    getPolicyRepoOverview(authInfo.accessToken ?? null).then((response) => {
      if (response !== null) {
        setPolicyRepoOverview(response);
      } else {
        toast.error("Failed to fetch policy repository overview");
      }
      setOverviewLoading(false);
    });
  }, []);

  return (
    <Layout pageName="Policy Repository">
      {policyRepoOverview && (
        <div className="grid grid-cols-3 gap-5 pb-10">
          <DocExplorer />
          <ReviewCard title="Action Required">
            <StandardDocsCard docs={policyRepoOverview.user_action_docs} />
          </ReviewCard>
          <ReviewCard title="Currently Processing">
            <StandardDocsCard
              docs={policyRepoOverview.currently_processing_docs}
            />
          </ReviewCard>
          <ReviewCard title="Recently Updated">
            <StandardDocsCard docs={policyRepoOverview.recently_updated_docs} />
          </ReviewCard>
          <ReviewCard title="Upcoming Reviews">
            <StandardDocsCard docs={policyRepoOverview.upcoming_docs} />
          </ReviewCard>
          <ReviewCard title="Attestations">
            <AttestationsCard
              attestations={policyRepoOverview.attestations}
              setPolicyRepoOverview={setPolicyRepoOverview}
            />
          </ReviewCard>
        </div>
      )}
      {overviewLoading && <LoadingView customText="Loading overview..." />}
    </Layout>
  );
};
