import React, { useEffect, useState } from "react";
import { CitationHighlight } from "./types";
import { usePdfHighlighter } from ".";
import { usePdf } from "../PdfContext";
import { HighlightTooltip } from "./HighlightTooltip";

type HighlightLayerProps = {
  page: number;
};

type CitationHighlightWithBoxRef = CitationHighlight & {
  boxRef?: React.MutableRefObject<HTMLDivElement | null>;
};
export const HighlightLayer: React.FC<HighlightLayerProps> = ({ page }) => {
  const { citations, citationToScroll, setCitationToScroll, currentPage } =
    usePdf();
  const { rangeHighlightsRef, onHighlightLayerRender, isHighlighting } =
    usePdfHighlighter();
  const [currentCitations, setCurrentCitations] = useState<
    CitationHighlightWithBoxRef[]
  >([]);

  const addRefToCitations = (citations: CitationHighlight[]) => {
    return citations.map((citation) => ({
      ...citation,
      boxRef: citation.highlightBoxes.length
        ? React.createRef<HTMLDivElement>()
        : undefined,
    }));
  };
  useEffect(() => {
    const highlights = onHighlightLayerRender(page);
    setCurrentCitations(addRefToCitations(highlights));
  }, []);

  useEffect(() => {
    const highlights = onHighlightLayerRender(page);
    setCurrentCitations(addRefToCitations(highlights));
  }, [citations]);

  useEffect(() => {
    if (currentCitations.length && citationToScroll && currentPage === page) {
      scrollToCitation(citationToScroll);
    }
  }, [currentPage, currentCitations]);

  const findTopCitation = () => {
    const topCitation = currentCitations.reduce((prev, current) => {
      return prev.boundingRect.top < current.boundingRect.top ? prev : current;
    });
    return topCitation;
  };
  const scrollToCitation = (citationId: string) => {
    const citation =
      citationId === "first"
        ? findTopCitation()
        : currentCitations.find((c) => c.id === citationId);
    if (citation && citation.boxRef?.current) {
      citation.boxRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }
    setCitationToScroll(null);
  };
  return (
    <div className={`PDF__highlight__div__layer page__${page}`}>
      {currentCitations.map((citation) => (
        <React.Fragment key={`${citation.id}`}>
          <CitationDisplay
            key={`ct-${citation.id}`}
            citation={citation}
            boxRef={citation.boxRef}
          />
          <HighlightTooltip
            key={`tt-${citation.id}`}
            isVisible={!!citation.match.length}
            highlightBox={citation.boundingRect}
            pageNum={page}
            toolTipClass={citation.tooltipClassName || ""}
          >
            <div>{citation.citationTooltip}</div>
          </HighlightTooltip>
        </React.Fragment>
      ))}
      {rangeHighlightsRef?.current &&
        rangeHighlightsRef.current.page === page && (
          <CitationDisplay citation={rangeHighlightsRef.current} />
        )}
    </div>
  );
};

type CitationDisplayProps = {
  citation: CitationHighlightWithBoxRef;
  boxRef?: React.MutableRefObject<HTMLDivElement | null>;
};
export const CitationDisplay: React.FC<CitationDisplayProps> = ({
  citation: citation,
  boxRef,
}) => {
  const [hover, sethover] = useState<boolean>(false);
  return (
    <div className={`PDF__highlight__container`} data-citation-id={citation.id}>
      {citation.highlightBoxes.map((box) => {
        return (
          <div
            key={`hlb-${box.top}-${box.left}`}
            className={`PDF__highlight__box ${hover ? citation.classNameOnHover : citation.className}`}
            style={{
              top: `${box.top}px`,
              left: `${box.left}px`,
              width: `${box.width}px`,
              height: `${box.height}px`,
              pointerEvents:
                typeof citation.onClick === "function" ? "all" : "none",
              cursor: `${typeof citation.onClick === "function" ? "pointer" : "auto"}`,
            }}
            onClickCapture={(e) => {
              if (typeof citation.onClick === "function") {
                citation.onClick();
              }
            }}
            onMouseEnter={() => {
              sethover(true);
            }}
            onMouseLeave={() => sethover(false)}
          ></div>
        );
      })}
      <div
        className="PDF__citation__maxbox"
        ref={boxRef}
        style={{
          top: citation.boundingRect.top,
          left: citation.boundingRect.left,
          width: citation.boundingRect.right - citation.boundingRect.left,
          height: citation.boundingRect.bottom - citation.boundingRect.top,
          position: "absolute",
          pointerEvents: "none",
        }}
      />
    </div>
  );
};
