import { useContext, useEffect, useRef, useState } from "react";
import { Button } from "../../shadcn/components/button";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "../../shadcn/components/dialog";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../shadcn/components/resizable";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import { BaseDocViewerControls, DocViewer } from "../../components/DocViewer";
import { PolicyRepoError } from "../../types";
import { checkPolicyRepoDocForErrors } from "../../utils/apiCalls";
import { useAuthInfo } from "@propelauth/react";
import { toast } from "sonner";
import { ReloadIcon } from "@radix-ui/react-icons";
import { cn } from "../../shadcn/lib/utils";
import { citationSortOrder } from "../../utils/format";

const DocView = (props: { activeDocId: string | null }) => {
  const { docToView, setDocToView, setScrollBoxRef } =
    useContext(DocViewerContext);
  const scrollBoxRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (props.activeDocId && docToView?.docId !== props.activeDocId) {
      setDocToView({
        docId: props.activeDocId,
      });
    }
  }, [props.activeDocId]);

  useEffect(() => {
    if (scrollBoxRef.current) {
      setScrollBoxRef(scrollBoxRef.current);
    }
  }, [scrollBoxRef, scrollBoxRef.current]);

  return (
    <div className={"space-y-4 mt-2 pl-4"}>
      <div className="flex items-center gap-4">
        <BaseDocViewerControls hideAtlasWidget={true} />
      </div>
      {docToView && (
        <div
          className="h-[calc(100vh-315px)] overflow-y-auto"
          ref={scrollBoxRef}
        >
          <DocViewer />
        </div>
      )}
    </div>
  );
};

const PolicyErrorView = (props: {
  error: PolicyRepoError;
  activeErrorIndex: number;
  setActiveErrorIndex: (index: number) => void;
  index: number;
}) => {
  return (
    <div
      className={cn(
        "p-5 rounded-md space-y-5",
        props.activeErrorIndex === props.index
          ? "bg-gray-300"
          : "bg-white hover:bg-gray-200 cursor-pointer"
      )}
      onClick={() => {
        props.setActiveErrorIndex(props.index);
      }}
    >
      <div className="text-gray-500 text-xs">{props.error.error}</div>
    </div>
  );
};

export const DocValidationView = (props: { docId: string }) => {
  const authInfo = useAuthInfo();
  const { setCitations, setPageNumber } = useContext(DocViewerContext);
  const [policyErrors, setPolicyErrors] = useState<PolicyRepoError[]>([]);
  const [errorsLoading, setErrorsLoading] = useState(false);
  const [activeErrorIndex, setActiveErrorIndex] = useState(0);

  const onCheckForErrors = async () => {
    setErrorsLoading(true);
    const response = await checkPolicyRepoDocForErrors(
      props.docId,
      authInfo.accessToken ?? ""
    );
    if (response !== null) {
      setPolicyErrors(response);
      setActiveErrorIndex(0);
    } else {
      setPolicyErrors([]);
      toast.error("Failed to check for errors");
    }
    setErrorsLoading(false);
  };

  useEffect(() => {
    if (activeErrorIndex < policyErrors.length) {
      setCitations([
        {
          match: policyErrors[activeErrorIndex].citation.text,
          exactMatch: false,
          page: policyErrors[activeErrorIndex].citation.page,
        },
      ]);
      setPageNumber(policyErrors[activeErrorIndex].citation.page);
    }
  }, [activeErrorIndex]);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button>Validate</Button>
      </DialogTrigger>
      <DialogContent className="max-w-[90vw] max-h-[90vh]">
        <DialogTitle>Document Validation</DialogTitle>
        <ResizablePanelGroup direction="horizontal">
          <ResizablePanel
            defaultSize={30}
            minSize={25}
            maxSize={50}
            id="error-navigation-panel"
            order={2}
          >
            <div className="space-y-2">
              <Button variant="default" onClick={onCheckForErrors}>
                Check for Errors
                {errorsLoading && (
                  <ReloadIcon className="ml-2 h-4 w-4 animate-spin" />
                )}
              </Button>
              <div className="h-[calc(100vh-215px)] overflow-y-auto">
                {policyErrors.sort(citationSortOrder).map((error, index) => (
                  <PolicyErrorView
                    error={error}
                    activeErrorIndex={activeErrorIndex}
                    setActiveErrorIndex={setActiveErrorIndex}
                    index={index}
                  />
                ))}
                {policyErrors.length === 0 && (
                  <div className="text-gray-500 text-center">
                    No errors found
                  </div>
                )}
              </div>
            </div>
          </ResizablePanel>
          <ResizableHandle withHandle />
          <ResizablePanel
            defaultSize={70}
            minSize={50}
            maxSize={70}
            id="doc-panel"
            order={3}
          >
            <DocView activeDocId={props.docId} />
          </ResizablePanel>
        </ResizablePanelGroup>
      </DialogContent>
    </Dialog>
  );
};
