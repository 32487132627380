import { Badge } from "../shadcn/components/badge";
import { Label } from "../shadcn/components/label";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "../shadcn/components/toggle-group";
import { cn } from "../shadcn/lib/utils";
import { TaskStatus } from "../types";

const Status = (props: { value: string; label: string; className: string }) => {
  return (
    <ToggleGroupItem
      value={props.value}
      aria-label={`Toggle ${props.value}`}
      className={cn(
        "h-7 w-18 rounded-full data-[state=on]:text-white data-[state=off]:text-gray-400",
        props.className
      )}
    >
      {props.label}
    </ToggleGroupItem>
  );
};

interface StatusOptions {
  value: string;
  label: string;
  className: string;
}

export const StatusSelector = (props: {
  status: string;
  handleStatusChange: (value: string) => Promise<void>;
  options: StatusOptions[];
  hideLabel?: boolean;
}) => {
  return (
    <div className="flex items-center space-x-3">
      {!props.hideLabel && <Label htmlFor="status">Mark as:</Label>}
      <ToggleGroup
        type="single"
        className="bg-white p-1 rounded-full"
        value={props.status}
        onValueChange={props.handleStatusChange}
      >
        {props.options.map((option) => (
          <Status
            key={option.value}
            value={option.value}
            label={option.label}
            className={option.className}
          />
        ))}
      </ToggleGroup>
    </div>
  );
};

export const StandardStatusSelector = (props: {
  status: string;
  handleStatusChange: (value: string) => Promise<void>;
}) => {
  return (
    <StatusSelector
      options={[
        {
          value: "todo",
          label: "To Do",
          className:
            "data-[state=on]:bg-red-400 data-[state=off]:hover:bg-red-100",
        },
        {
          value: "review",
          label: "Review",
          className:
            "data-[state=on]:bg-yellow-400 data-[state=off]:hover:bg-yellow-100",
        },
        {
          value: "done",
          label: "Done",
          className:
            "data-[state=on]:bg-green-400 data-[state=off]:hover:bg-green-100",
        },
      ]}
      status={props.status}
      handleStatusChange={props.handleStatusChange}
    />
  );
};

export const StatusBadge = (props: { status: TaskStatus }) => {
  return (
    <div>
      {props.status === "done" ? (
        <Badge className="bg-green-400">Done</Badge>
      ) : props.status === "review" ? (
        <Badge className="bg-yellow-400">Review</Badge>
      ) : (
        <Badge className="bg-red-400">To Do</Badge>
      )}
    </div>
  );
};
