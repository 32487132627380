import { useAuthInfo } from "@propelauth/react";
import { Layout } from "../../components/Layout";
import { useParams } from "react-router-dom";
import { getAuditResources } from "../../utils/apiCalls";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { AuditResource } from "../../types";
import { LoadingView } from "../../components/Loading";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../shadcn/components/breadcrumb";
import { FileQuestionIcon, TableIcon, UploadIcon } from "lucide-react";
import { StatusBadge } from "../../components/StatusSelector";

const BreadcrumbNav = () => {
  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink href="/audit">Audits</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage>Audit Resources</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );
};

const ResourceCard = (props: { resource: AuditResource; auditId: string }) => {
  let icon;
  switch (props.resource.resource_type) {
    case "questionnaire":
      icon = <FileQuestionIcon className="w-4 h-4" />;
      break;
    case "upload":
      icon = <UploadIcon className="w-4 h-4" />;
      break;
    case "table":
      icon = <TableIcon className="w-4 h-4" />;
      break;
  }

  return (
    <a
      href={`/audit/${props.auditId}/${props.resource.resource_type}/${props.resource.id}`}
    >
      <div className="bg-white w-[600px] p-5 rounded-md hover:bg-gray-200 cursor-pointer flex items-center justify-between">
        <div className="flex items-center space-x-4 max-w-[80%]">
          {icon}
          <div className="text-md font-bold">{props.resource.name}</div>
        </div>
        <StatusBadge status={props.resource.status} />
      </div>
    </a>
  );
};

export const AuditResourceView = () => {
  const { auditId } = useParams();
  const authInfo = useAuthInfo();
  const [auditResourcesLoading, setAuditResourcesLoading] = useState(false);
  const [auditResources, setAuditResources] = useState<AuditResource[]>([]);

  useEffect(() => {
    setAuditResourcesLoading(true);
    getAuditResources(auditId ?? "", authInfo.accessToken ?? null).then(
      (auditResources) => {
        if (auditResources) {
          setAuditResources(auditResources);
        } else {
          toast.error("Failed to fetch audit resources");
        }
        setAuditResourcesLoading(false);
      }
    );
  }, []);

  return (
    <Layout pageName="Audits">
      <BreadcrumbNav />
      <div className="flex justify-center space-y-2">
        {auditResourcesLoading && (
          <LoadingView customText="Loading resources..." />
        )}
        <div className="flex flex-col space-y-2">
          {auditResources.map((resource) => (
            <ResourceCard
              key={resource.id}
              resource={resource}
              auditId={auditId ?? ""}
            />
          ))}
        </div>
      </div>
    </Layout>
  );
};
