import Markdown from "react-markdown";
import { Badge } from "../shadcn/components/badge";
import remarkGfm from "remark-gfm";
import { extractTag } from "../utils/format";
import { Citation } from "../types";
import { Copy } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../shadcn/components/tooltip";
import { toast } from "sonner";
import { Button } from "../shadcn/components/button";

const formatCitationText = (
  text: string,
  replaceFormat: (citationId: string, index: number) => string
) => {
  let fmtText = text;
  const citationIdsRaw = extractTag(text, "citation");
  const citationIds = citationIdsRaw
    .map((citationId) => citationId.split(","))
    .flatMap((citation) => citation);

  citationIds
    .filter((citationId) => citationId.length > 0)
    .forEach((citationId, i) => {
      const regex = new RegExp(`(${citationId})(?!~)`, "g");
      fmtText = fmtText.replace(regex, replaceFormat(citationId, i));
    });

  fmtText = fmtText.replaceAll("<citation>", "");
  fmtText = fmtText.replaceAll("</citation>", "");
  fmtText = fmtText.replaceAll("~,~", "~ ~");

  return fmtText;
};

export const MarkdownCitationDisplay = (props: {
  text: string;
  citations: Citation[];
  onCitationClick: (citationId: string) => void;
}) => {
  const handleCopy = () => {
    const { text, citations } = props;
    let fmtText = formatCitationText(text, (_, i) => `[${i + 1}]`);

    // Remove additional formatting tags for plain text
    fmtText = fmtText.replace(/<\/?[^>]+(>|$)|\*\*|##+|`/g, "");

    const citationsText = citations
      .filter((citation) => citation.id && text.includes(citation.id))
      .map(
        (citation, i) =>
          `[${i + 1}] ${citation.doc_name} - Page ${citation.page}`
      )
      .join("\n");

    const textToCopy = `${fmtText}\n\nReferences:\n${citationsText}`;
    navigator.clipboard.writeText(textToCopy);
    toast.success("Copied to clipboard");
  };

  const { text, onCitationClick, citations } = props;
  const fmtText = formatCitationText(
    text,
    (citationId, i) => `~${i + 1}<>${citationId}~`
  );

  return (
    <div className="relative rounded-lg border bg-card text-card-foreground shadow-sm p-4 pr-10">
      <div className="absolute top-2 right-2">
        <Button variant="ghost" size="icon" onClick={handleCopy}>
          <Copy className="h-4 w-4" />
        </Button>
      </div>
      <Markdown
        className="prose"
        remarkPlugins={[remarkGfm]}
        components={{
          del: ({ node, ...props }) => {
            const [index, citationId] = (props.children as string).split("<>");
            const cleanCitationId = citationId.trim();
            const citation = citations.find(
              (citation) => citation.id === cleanCitationId
            );

            const badge = (
              <Tooltip>
                <TooltipTrigger>
                  <Badge
                    variant="default"
                    className="text-xs hover:bg-gray-100 hover:text-black cursor-pointer"
                    onClick={() => {
                      onCitationClick(cleanCitationId);
                    }}
                  >
                    {index}
                  </Badge>
                </TooltipTrigger>
                <TooltipContent>
                  {citation ? (
                    <div className="space-y-2 text-xs w-[200px]">
                      <div className="font-semibold truncate ellipsis">
                        {citation.doc_name}
                      </div>
                      <div className="line-clamp-4">{citation.text}</div>
                    </div>
                  ) : null}
                </TooltipContent>
              </Tooltip>
            );
            return badge;
          },
        }}
      >
        {fmtText}
      </Markdown>
    </div>
  );
};

const replaceCustomTags = (text: string): string => {
  return text
    .replace(/<strikethrough>(.*?)<\/strikethrough>/g, "**~~$1~~**")
    .replace(/<underline>(.*?)<\/underline>/g, "**`$1`**");
};

export const MarkdownRedlineDisplay = (props: { text: string }) => {
  const fmtText = replaceCustomTags(props.text);
  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      components={{
        code: ({ node, ...props }) => {
          return <span className="underline">{props.children}</span>;
        },
      }}
    >
      {fmtText}
    </Markdown>
  );
};
