import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  ReactNode,
} from "react";
import { getDocument, PDFDocumentProxy } from "pdfjs-dist";
import "./style.css";
import { PageDimensions, usePdf } from "./PdfContext";
import debounce from "debounce";
import Page from "./Page";
import { PdfHighlighterProvider } from "./PdfHighlighter";
import { DocumentInitParameters } from "pdfjs-dist/types/src/display/api";
import PdfLazyLoader from "./LazyLoader";

interface PdfViewerProps {
  url: string;
  fileOptions?: any;
  documentLoadingContnet?: ReactNode;
  pageLoadingContent?: ReactNode;
  tooltipClassName?: string;
}

const PdfViewer: React.FC<PdfViewerProps> = ({
  url,
  fileOptions = {},
  documentLoadingContnet,
  pageLoadingContent,
}) => {
  const {
    documentStatus,
    setdocumentStatus,
    onDocumentLoad,
    pdfDocument,
    setPdfDocument,
    numPages,
    setNumPages,
    scale,
    setScale,
    currentPage,
    pageDimensions,
    setPageDimensions,
    containerRef,
    listRef,
    overscanCount,
    setCitationToScroll,
  } = usePdf();
  const latestUrlRef = useRef(url);
  const [pdfPadding, setPdfPadding] = useState({ w: 40, h: 15 });
  // Calculate row height for each page
  const computeRowHeight = useCallback(
    (index: number) => {
      return (pageDimensions?.get(index + 1)?.height || 0) * scale;
    },
    [pageDimensions, scale]
  );

  // Load the PDF document
  useEffect(() => {
    const loadDocument = async (urlToLoad: string) => {
      const docParams: DocumentInitParameters = {
        url: urlToLoad,
        ...fileOptions,
      };
      let pdfDoc: PDFDocumentProxy;
      const loadingTask = getDocument(docParams);
      try {
        pdfDoc = await loadingTask.promise;
      } catch (error) {
        console.error(error);
        setdocumentStatus("ERROR");
        return;
      }
      if (urlToLoad !== latestUrlRef.current) {
        return;
      }
      const dimensions: Map<number, PageDimensions> = new Map();
      let totalHeight = 0;
      for (let pageNum = 1; pageNum <= pdfDoc.numPages; pageNum++) {
        const page = await pdfDoc.getPage(pageNum);
        const viewport = page.getViewport({ scale: 1.0 });
        dimensions.set(pageNum, {
          width: viewport.width,
          height: viewport.height,
          top: totalHeight,
        });
        totalHeight += viewport.height;
      }
      const container = containerRef.current;
      if (container) {
        const { width: containerWidth } = container.getBoundingClientRect();
        const firstPage = await pdfDoc?.getPage(1);
        const viewport = firstPage?.getViewport({ scale: 1.0 });
        const pdfOriginalViewportWidth = viewport?.width || 0;
        const containerWidthWithPadding = containerWidth - pdfPadding.w;
        setScale(containerWidthWithPadding / pdfOriginalViewportWidth);
      }
      setPageDimensions(dimensions);
      setPdfDocument(pdfDoc);
      setNumPages(pdfDoc.numPages);
      onDocumentLoad();
      setdocumentStatus("READY");
    };
    latestUrlRef.current = url;
    setdocumentStatus("LOADING");
    setCitationToScroll("first");
    setPdfDocument(null);
    loadDocument(url);
  }, [url]);

  const handleResizeDebounced = useCallback(
    debounce(async (entries) => {
      if (!entries || entries.length === 0) return;

      // Get the first page of the PDF to determine the original viewport width
      const page = await pdfDocument?.getPage(1);
      const viewport = page?.getViewport({ scale: 1.0 });
      if (!viewport) return;
      const pdfOriginalViewportWidth = viewport?.width || 0;
      const { width: containerWidth } = entries[0].contentRect;
      const containerWidthWithPadding = containerWidth - pdfPadding.w;
      setScale(containerWidthWithPadding / pdfOriginalViewportWidth);
    }, 500),
    [pdfDocument, currentPage, scale, setScale]
  );

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    const resizeObserver = new ResizeObserver(handleResizeDebounced);
    resizeObserver.observe(container);
    return () => {
      resizeObserver.unobserve(container);
    };
  }, [pdfDocument, scale]);

  // Reset row heights after scale changes
  useEffect(() => {
    if (listRef.current && documentStatus === "READY") {
      listRef.current.resetScale();
      listRef.current.scrollToPage(currentPage);
    }
  }, [scale]);

  const renderPageInLazy = useCallback(
    (index: number) => (
      <Page
        pdfDocument={pdfDocument!}
        pageNumber={index}
        pageHeight={computeRowHeight(index - 1)}
        pageTop={
          (pageDimensions.get(index)?.top || 0) * scale +
          pdfPadding.h * index -
          pdfPadding.h / 2
        }
        scale={scale}
        pageLoadingContent={pageLoadingContent}
      />
    ),
    [scale, pdfDocument, pageDimensions]
  );

  return (
    <PdfHighlighterProvider>
      <div
        style={{
          height: "100%",
          maxHeight: "200vh",
          border: "1px solid rgb(213 213 213)",
          borderRadius: "5px",
        }}
      >
        <div
          ref={containerRef}
          style={{
            height: "100%",
            maxHeight: "200vh",
            position: "relative",
            backgroundColor: "#9b9a9a",
          }}
        >
          {documentStatus === "LOADING" &&
            (documentLoadingContnet ? (
              documentLoadingContnet
            ) : (
              <div>Loading Document...</div>
            ))}
          {documentStatus === "READY" && (
            <PdfLazyLoader
              ref={listRef}
              numPages={numPages}
              computeRowHeight={(index) =>
                computeRowHeight(index) + pdfPadding.h
              }
              overscan={overscanCount}
              renderPage={renderPageInLazy}
            />
          )}
          {documentStatus === "ERROR" && (
            <div className="text-center">Error loading document</div>
          )}
        </div>
      </div>
    </PdfHighlighterProvider>
  );
};

export default PdfViewer;
