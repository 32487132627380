import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Box } from "./types";
import ReactDOM from "react-dom";
import { usePdf } from "../PdfContext";
import debounce from "debounce";

type HighlightToolTipProps = {
  isVisible: boolean;
  highlightBox: Box;
  pageNum?: number;
  toolTipClass?: string;
  activeModalRef?: React.MutableRefObject<Element>;
};
export const HighlightTooltip: React.FC<
  PropsWithChildren & HighlightToolTipProps
> = ({
  children,
  isVisible,
  highlightBox,
  pageNum,
  toolTipClass,
  activeModalRef,
}) => {
  const { scrollOffset } = usePdf();
  const toolTipRef = useRef<HTMLDivElement | null>(null);
  const [toolTipSize, setToolTipSize] = useState<number>(0);
  const [containerPosition, setContainerPosition] = useState({
    top: 0,
    left: 0,
    height: 0,
    bottom: 0,
  });
  const { pageRefs, containerRef } = usePdf();
  const [toolTipH, setToolTipH] = useState(0);
  const [toolTipR, setToolTipR] = useState(0);
  const [isHighlightOob, setIsHighlightOob] = useState(false);

  const calculateTooltipHeight = () => {
    if (!pageNum) {
      setIsHighlightOob(true);
      return;
    }
    const pageElement = pageRefs.current?.get(pageNum);
    if (!pageElement) return;
    const pageTop = parseFloat(
      pageElement.getAttribute("data-page-top") || "0"
    );
    const calcH =
      pageTop - scrollOffset + highlightBox.top + containerPosition.top;
    const isOutOfBounds =
      pageTop + highlightBox.bottom < scrollOffset ||
      pageTop + highlightBox.top > scrollOffset + containerPosition.height;

    const boundedHeight = Math.max(
      Math.min(calcH, containerPosition.bottom - toolTipSize),
      containerPosition.top
    );
    setToolTipH(boundedHeight);
    setToolTipR(
      window.innerWidth - containerPosition.left - highlightBox.left + 10
    );

    setIsHighlightOob(isOutOfBounds);
  };

  const updateContainerPosition = () => {
    const container = containerRef.current;
    if (container) {
      const containerRect = container.getBoundingClientRect();
      setContainerPosition({
        top: containerRect.top,
        left: containerRect.left,
        height: containerRect.height,
        bottom: containerRect.bottom,
      });
    }
  };

  useEffect(() => {
    updateContainerPosition();
    calculateTooltipHeight();
  }, [scrollOffset, containerRef]);

  useEffect(() => {
    updateContainerPosition();
  }, []);
  useEffect(() => {
    calculateTooltipHeight();
  }, [containerPosition]);

  useEffect(() => {
    calculateTooltipHeight();
  }, [pageNum, highlightBox]);

  const updateContainerPositionDebounced = debounce(
    updateContainerPosition,
    5000
  );
  useEffect(() => {
    updateContainerPosition();

    window.addEventListener("resize", updateContainerPositionDebounced);

    return () => {
      window.removeEventListener("resize", updateContainerPositionDebounced);
    };
  }, []);

  useEffect(() => {
    const toolTipSize = toolTipRef.current?.getBoundingClientRect().height || 0;
    setToolTipSize(toolTipSize);
  }, [children, toolTipClass]);

  return ReactDOM.createPortal(
    <div
      className={`absolute z-[100] ${toolTipClass}`}
      style={{
        top: `${Math.floor(toolTipH)}px`,
        right: `${Math.floor(toolTipR)}px`,
        opacity: isVisible && !isHighlightOob ? "1" : "0",
        pointerEvents: isVisible && !isHighlightOob ? "all" : "none",
        transition: `opacity 100ms`,
      }}
      ref={toolTipRef}
    >
      {children}
    </div>,
    activeModalRef?.current
      ? activeModalRef?.current
      : (document.getElementById("root") as Element)
  );
};
