import { useEffect, useState } from "react";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "../../../shadcn/components/sheet";

const DataSection = (props: { title: string; value: string }) => {
  return (
    <div className="space-y-2">
      <div className="font-bold">{props.title}</div>
      <div className="text-gray-500">{props.value}</div>
    </div>
  );
};

export const DashboardRecord = (props: {
  record: Record<string, any>;
  setActiveRecord: (record: Record<string, any> | null) => void;
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, [props.record]);

  return (
    <Sheet
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          props.setActiveRecord(null);
        }
        setOpen(open);
      }}
    >
      <SheetContent style={{ maxWidth: "50vw" }}>
        <SheetHeader>
          <SheetTitle>{props.record.document}</SheetTitle>
          <SheetDescription>{props.record.document_location}</SheetDescription>
        </SheetHeader>
        <div className="pt-4 space-y-4 overflow-y-auto max-h-[calc(100vh-100px)]">
          <DataSection title="Requirement" value={props.record.requirement} />
          <DataSection title="Status" value={props.record.status} />
          <DataSection
            title="Departments"
            value={props.record.departments.join(", ")}
          />
        </div>
      </SheetContent>
    </Sheet>
  );
};
