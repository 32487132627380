import React from "react";
import { DataTable, HeaderCell } from "../../components/Table";
import { Badge } from "../../shadcn/components/badge";
import { Button } from "../../shadcn/components/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";

export const completedCheck = (status: string) => {
  return [
    "APL Closed or N/A",
    "Approved Workplan Completed",
    "Approved No Work Plan",
  ].includes(status);
};

const TruncatedWTooltip = (props: { text: string }) => {
  return (
    <Tooltip>
      <TooltipTrigger>
        {props.text.slice(0, 20)}
        {props.text.length > 20 && "..."}
      </TooltipTrigger>
      <TooltipContent>{props.text}</TooltipContent>
    </Tooltip>
  );
};

export const DashboardTable = (props: {
  data: Record<string, any>[];
  setActiveRow: (row: Record<string, any>) => void;
  endDate: Date;
  children: React.ReactNode;
}) => {
  const currentDate = new Date();
  let columns: any[] = [
    {
      header: <span></span>,
      id: "view-button",
      cell: ({ row }: any) => {
        return (
          <Button
            variant="ghost"
            className="text-blue-500 underline"
            onClick={() => props.setActiveRow(row.original)}
          >
            View
          </Button>
        );
      },
    },
    {
      header: ({ column }: any) => {
        return <HeaderCell column={column} columnName="Status" />;
      },
      cell: ({ row }: any) => {
        let badge;
        if (completedCheck(row.original.WorkflowStatus)) {
          badge = <Badge className="bg-green-500">Complete</Badge>;
        } else {
          if (new Date(row.original["APL_DateDue"]) <= currentDate) {
            badge = <Badge className="bg-red-500">Past Due</Badge>;
          } else {
            badge = <Badge className="bg-yellow-500">Pending</Badge>;
          }
        }
        return badge;
      },
      id: "status-badge",
    },
    {
      header: ({ column }: any) => {
        return <HeaderCell column={column} columnName="Regulator" />;
      },
      accessorKey: "APL_Regulator",
    },
    {
      header: ({ column }: any) => {
        return <HeaderCell column={column} columnName="Document" />;
      },
      cell: ({ row }: any) => {
        return <TruncatedWTooltip text={row.original.APL_APLName} />;
      },
      accessorKey: "APL_APLName",
    },
    {
      header: ({ column }: any) => {
        return <HeaderCell column={column} columnName="Released" />;
      },
      accessorKey: "APL Release Date",
    },
    {
      header: ({ column }: any) => {
        return <HeaderCell column={column} columnName="Due" />;
      },
      accessorKey: "APL_DateDue",
    },
    {
      header: ({ column }: any) => {
        return <HeaderCell column={column} columnName="Implemented" />;
      },
      accessorKey: "APL Implementation Date",
    },
    {
      header: ({ column }: any) => {
        return <HeaderCell column={column} columnName="Compliance Status" />;
      },
      accessorKey: "APL_ReviewLevelofCompliance",
    },
    {
      header: ({ column }: any) => {
        return <HeaderCell column={column} columnName="Workplan Status" />;
      },
      accessorKey: "WorkflowStatus",
    },
    {
      header: ({ column }: any) => {
        return <HeaderCell column={column} columnName="Employee" />;
      },
      accessorKey: "Employee Name",
    },
    {
      header: ({ column }: any) => {
        return <HeaderCell column={column} columnName="Responsible Party" />;
      },
      cell: ({ row }: any) => {
        return <TruncatedWTooltip text={row.original["Job Title"]} />;
      },
      accessorKey: "Job Title",
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={props.data}
      paginationAtTop={true}
      headerChildren={props.children}
      paginationParams={{ pageSize: 50, pageIndex: 0 }}
      totalRecordCount={props.data.length}
    />
  );
};
