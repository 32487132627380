import { Button } from "../../shadcn/components/button";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Textarea } from "../../shadcn/components/textarea";
import { Separator } from "../../shadcn/components/separator";
import Markdown from "react-markdown";
import {
  ChatMessageType,
  Citation,
  ModelChat,
  SearchResource,
} from "../../types";
import { MarkdownCitationDisplay } from "../../components/MarkdownDisplay";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import { extractTag } from "../../utils/format";
import { Badge } from "../../shadcn/components/badge";
import { cn } from "../../shadcn/lib/utils";
import { ReloadIcon } from "@radix-ui/react-icons";
import { ChatHighlightActions } from "./ChatHighlightActions";
import { useChatContext } from "../../contexts/ChatContext";

const UserMessageContainer = (props: {
  message: ModelChat;
  onCitationClick: (citationId: string) => void;
}) => {
  let fmtMessage = props.message.content;
  let citationId: string | null = null;
  let messageType: ChatMessageType = "message";

  // extract message type
  const messageTypes = extractTag(props.message.content, "messageType");
  if (messageTypes.length > 0) {
    messageType = messageTypes[0] as ChatMessageType;
    // remove message type tag and it's content from the message
    fmtMessage = fmtMessage
      .replace(`<messageType>${messageTypes[0]}</messageType>`, "")
      .trim();

    // extract citation id
    const citationIds = extractTag(props.message.content, "citation");
    if (citationIds.length > 0) {
      citationId = citationIds[0];
      fmtMessage = fmtMessage
        .replace(`<citation>${citationIds[0]}</citation>`, "")
        .trim();
    }

    // remove relvant text tag
    fmtMessage = fmtMessage
      .replace(`<relevantText>`, "")
      .replace(`</relevantText>`, "")
      .trim();
  }
  return (
    <div className="flex justify-end w-full">
      <div
        className={cn(
          "bg-gray-300 rounded-3xl px-5 py-3 space-y-2 mt-5 mb-5 max-w-[90%]",
          citationId !== null &&
            "hover:cursor-pointer hover:bg-black hover:text-white"
        )}
        onClick={() => {
          if (citationId !== null) {
            props.onCitationClick(citationId);
          }
        }}
      >
        {messageType !== "message" && (
          <Badge>
            {(
              messageType.charAt(0).toUpperCase() + messageType.slice(1)
            ).replace("_", " ")}
          </Badge>
        )}
        <Markdown>{fmtMessage}</Markdown>
      </div>
    </div>
  );
};

const ChatMessageContainer = (props: {
  message: ModelChat;
  citations: Citation[];
  onCitationClick: (citationId: string) => void;
  lastDivRef: React.RefObject<HTMLDivElement> | null;
}) => {
  return (
    <div ref={props.lastDivRef}>
      {props.message.role === "user" ? (
        <UserMessageContainer
          message={props.message}
          onCitationClick={props.onCitationClick}
        />
      ) : (
        <div className="space-y-2 mt-5 mb-5">
          <MarkdownCitationDisplay
            text={props.message.content}
            citations={props.citations}
            onCitationClick={props.onCitationClick}
          />
        </div>
      )}
    </div>
  );
};

const ChatPanel = (props: {
  chatLoading: boolean;
  userMessage: (
    message: string,
    pageNumber: number,
    docHighlight: boolean
  ) => void;
}) => {
  const { pageNumber } = useContext(DocViewerContext);
  const [chatInput, setChatInput] = useState("");

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ): void => {
    if (
      event.key === "Enter" &&
      !event.shiftKey &&
      !event.nativeEvent.isComposing &&
      !props.chatLoading
    ) {
      event.preventDefault();
      if (chatInput !== "") {
        props.userMessage(chatInput, pageNumber, false);
      }
    }
  };

  useEffect(() => {
    if (!props.chatLoading) {
      setChatInput("");
    }
  }, [props.chatLoading]);

  return (
    <div className="relative py-2 pl-1 pr-1">
      <Textarea
        tabIndex={0}
        onKeyDown={handleKeyDown}
        rows={3}
        value={chatInput}
        onChange={(e) => setChatInput(e.target.value)}
        placeholder={`Ask a question...`}
        spellCheck={false}
        className="pr-20"
        disabled={props.chatLoading}
      />
      <div className="absolute right-0 top-8 sm:right-4">
        <Button
          onClick={() => props.userMessage(chatInput, pageNumber, false)}
          size="sm"
          disabled={chatInput === "" || props.chatLoading}
        >
          Send{" "}
          {props.chatLoading && (
            <ReloadIcon className="w-4 h-4 ml-2 animate-spin" />
          )}
        </Button>
      </div>
    </div>
  );
};

export const ChatView = (props: {
  chatHighlightCallback?: () => void;
  citationTooltip?: boolean;
}) => {
  const {
    chatMessages,
    chatLoading,
    activeResourceId,
    sendUserMessage,
    selectedResources,
    setSelectedResources,
    setActiveResourceId,
  } = useChatContext();
  const { setCitations, setPageNumber } = useContext(DocViewerContext);
  const lastDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (lastDivRef && lastDivRef.current && chatLoading) {
      lastDivRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatMessages]);

  const citationClickOnDoc = (citation: Citation) => {
    setPageNumber(citation.page);
    setCitations([
      {
        id: citation.id!,
        match: citation.text,
        exactMatch: false,
        page: citation.page,
        citationTooltip: props.citationTooltip ? (
          <ChatHighlightActions
            citationId={citation.id!}
            setMessage={sendUserMessage}
            onClickCallback={props.chatHighlightCallback}
          />
        ) : null,
      },
    ]);
  };

  const onCitationClick = (citationId: string) => {
    const citation = chatMessages?.citations.find(
      (citation) => citation.id === citationId
    );
    if (citation) {
      if (citation.doc_id && citation.doc_id !== activeResourceId) {
        // see if the resource is already in the selected resources
        const existingResource = selectedResources.find(
          (resource) => resource.id === citation.doc_id
        );
        if (!existingResource) {
          // add the resource to the selected resources
          setSelectedResources((prevResources) => [
            ...prevResources,
            {
              id: citation.doc_id,
              name: citation.doc_name,
              type: "document",
              match_type: "name",
              additional_metadata: {},
              doc_type_name: null,
              citation: null,
              regulatory_doc_id: null,
              count: null,
            } as SearchResource,
          ]);
        }
        setActiveResourceId(citation.doc_id!);
        setTimeout(() => {
          citationClickOnDoc(citation);
        }, 200);
      } else {
        citationClickOnDoc(citation);
      }
    }
  };

  return (
    <>
      <div className="h-[calc(100vh-208px)]">
        <div className="h-[calc(100vh-300px)] overflow-y-auto blur-content">
          <div className="space-y-2 flex justify-center">
            <div className="w-full">
              {chatMessages !== null &&
                chatMessages.chat.map((message, index) => (
                  <Fragment key={`chat-${index}`}>
                    <ChatMessageContainer
                      message={message}
                      citations={chatMessages.citations}
                      onCitationClick={onCitationClick}
                      lastDivRef={
                        index === chatMessages.chat.length - 1
                          ? lastDivRef
                          : null
                      }
                    />
                    {index < chatMessages.chat.length - 1 && <Separator />}
                  </Fragment>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 bg-secondary w-full">
        <ChatPanel chatLoading={chatLoading} userMessage={sendUserMessage} />
      </div>
    </>
  );
};
