import React, { createContext, useContext, useState } from "react";
import { Citation, SearchResource } from "../types";
import { ModelChat } from "../types";
import { chatDoc } from "../utils/apiCalls";
import { toast } from "sonner";
import { useAuthInfo } from "@propelauth/react";

interface ChatContextProps {
  chatMessages: {
    chat: ModelChat[];
    citations: Citation[];
  } | null;
  setChatMessages: React.Dispatch<
    React.SetStateAction<{
      chat: ModelChat[];
      citations: Citation[];
    } | null>
  >;
  activeResourceId: string | null;
  setActiveResourceId: React.Dispatch<React.SetStateAction<string | null>>;
  selectedResources: SearchResource[];
  setSelectedResources: React.Dispatch<React.SetStateAction<SearchResource[]>>;
  activeResource: SearchResource | undefined;
  chatLoading: boolean;
  setChatLoading: React.Dispatch<React.SetStateAction<boolean>>;
  sendUserMessage: (
    input: string,
    pageNumber: number,
    docHighlight: boolean
  ) => Promise<void>;
  chatId: string | null;
  setChatId: React.Dispatch<React.SetStateAction<string | null>>;
}

const ChatContext = createContext<ChatContextProps>({
  chatMessages: null,
  setChatMessages: () => {},
  activeResourceId: null,
  setActiveResourceId: () => {},
  selectedResources: [],
  setSelectedResources: () => {},
  activeResource: undefined,
  chatLoading: false,
  setChatLoading: () => {},
  sendUserMessage: async () => {},
  chatId: null,
  setChatId: () => {},
});

export const ChatProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const authInfo = useAuthInfo();
  const [chatId, setChatId] = useState<string | null>(null);
  const [chatMessages, setChatMessages] = useState<{
    chat: ModelChat[];
    citations: Citation[];
  } | null>(null);
  const [chatLoading, setChatLoading] = useState(false);
  const [activeResourceId, setActiveResourceId] = useState<string | null>(null);
  const [selectedResources, setSelectedResources] = useState<SearchResource[]>(
    []
  );
  const activeResource = selectedResources.find(
    (resource) => resource.id === activeResourceId
  );

  const sendUserMessage = async (
    input: string,
    pageNumber: number,
    docHighlight: boolean
  ) => {
    setChatMessages((prevMessages) => {
      return {
        chat: [...(prevMessages?.chat ?? []), { role: "user", content: input }],
        citations: prevMessages?.citations ?? [],
      };
    });
    let resourcesToSubmit: SearchResource[];
    if (docHighlight && activeResource) {
      resourcesToSubmit = [activeResource];
    } else {
      resourcesToSubmit = selectedResources;
    }

    setChatLoading(true);
    try {
      for await (const chatOutput of chatDoc(
        chatId,
        input,
        resourcesToSubmit,
        pageNumber,
        authInfo.accessToken ?? null
      )) {
        setChatId(chatOutput.chat_id);
        if (chatOutput.output_message !== null) {
          setChatMessages((prevMessages) => {
            if (prevMessages) {
              // Add new citations to the citation map
              let updatedCitations = [...prevMessages.citations];
              chatOutput.citations.forEach((citation) => {
                updatedCitations = updatedCitations.filter(
                  (existingCitation) => existingCitation.id !== citation.id
                );
                updatedCitations.push(citation);
              });
              if (
                prevMessages.chat.length > 1 &&
                prevMessages.chat[prevMessages.chat.length - 1].role ===
                  "assistant"
              ) {
                return {
                  ...prevMessages,
                  chat: [
                    ...prevMessages.chat.slice(0, prevMessages.chat.length - 1),
                    chatOutput.output_message!,
                  ],
                  citations: updatedCitations,
                };
              } else {
                return {
                  ...prevMessages,
                  chat: [...prevMessages.chat, chatOutput.output_message!],
                  citations: updatedCitations,
                };
              }
            }
            return prevMessages;
          });
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong, try again");
    }
    setChatLoading(false);
  };

  return (
    <ChatContext.Provider
      value={{
        chatMessages,
        setChatMessages,
        activeResourceId,
        setActiveResourceId,
        selectedResources,
        setSelectedResources,
        activeResource,
        chatLoading,
        setChatLoading,
        sendUserMessage,
        chatId,
        setChatId,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => {
  return useContext(ChatContext);
};
