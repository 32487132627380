import { useAuthInfo } from "@propelauth/react";
import { Layout } from "../../components/Layout";
import { createGapAnalysis, getGapAnalyses } from "../../utils/apiCalls";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { GapAnalysis, SearchDocName, SearchResource } from "../../types";
import { Input } from "../../shadcn/components/input";
import { LoadingView } from "../../components/Loading";
import { TimeAgo } from "../../utils/format";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../shadcn/components/dialog";
import { Button } from "../../shadcn/components/button";
import { PlusIcon, ReloadIcon } from "@radix-ui/react-icons";
import { SearchResourceBar } from "../../components/Resource/SearchResource";
import { Label } from "../../shadcn/components/label";
import { toggleAtlasWidget } from "../../utils/cookies";
import { Badge } from "../../shadcn/components/badge";

const NewGapAnalysisDialog = (props: {
  setGapAnalysis: React.Dispatch<React.SetStateAction<GapAnalysis[]>>;
  hideAtlasWidget?: boolean;
}) => {
  const authInfo = useAuthInfo();
  const [open, setOpen] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [name, setName] = useState("");
  const [searchDoc, setSearchDoc] = useState<SearchResource | null>(null);

  const onClickSave = async () => {
    setSaveLoading(true);
    const response = await createGapAnalysis(
      name,
      searchDoc!.id,
      authInfo.accessToken ?? null
    );
    if (response !== null) {
      toast.success("Gap analysis created");
      props.setGapAnalysis((prev) => [
        ...prev,
        {
          id: response.id,
          name: name,
          doc_id: searchDoc!.id,
          doc_name: searchDoc!.name,
          doc_type_name: searchDoc!.doc_type_name,
          created_at: new Date().toISOString().slice(0, -1),
          updated_at: new Date().toISOString().slice(0, -1),
        } as GapAnalysis,
      ]);
      setOpen(false);
    } else {
      toast.error("Failed to create gap analysis");
    }
    setSaveLoading(false);
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        if (props.hideAtlasWidget) {
          toggleAtlasWidget(open);
        }
      }}
    >
      <DialogTrigger asChild>
        <Button variant="default" size="sm">
          <PlusIcon className="w-4 h-4 mr-2" />
          New Analysis
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-[90%]">
        <DialogHeader>
          <div className="flex items-center justify-between">
            <DialogTitle>New Gap Analysis</DialogTitle>
            <Button
              variant="default"
              className="w-[120px] mr-12"
              onClick={onClickSave}
              disabled={saveLoading || !name || !searchDoc}
            >
              Save
              {saveLoading && (
                <ReloadIcon className="w-4 h-4 ml-2 animate-spin" />
              )}
            </Button>
          </div>
        </DialogHeader>
        <div className="space-y-1">
          <Label htmlFor="gap-analysis-name">Name</Label>
          <Input
            placeholder="Enter name..."
            value={name}
            onChange={(e) => setName(e.target.value)}
            id="gap-analysis-name"
          />
        </div>
        <div className="space-y-1">
          <Label htmlFor="gap-analysis-search-doc" className="block">
            Document
          </Label>
          {searchDoc && <Badge className="text-xs">{searchDoc.name}</Badge>}
          <SearchResourceBar
            docTypeIds={[]}
            onItemSelect={setSearchDoc}
            placeholder="Search Documents..."
            autoSelect={searchDoc === null}
            hideAtlasWidget={false}
            className="h-[300px]"
            docNameOnly={true}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const GapAnalysisView = () => {
  const authInfo = useAuthInfo();
  const [gapAnalysis, setGapAnalysis] = useState<GapAnalysis[]>([]);
  const [gapAnalysisLoading, setGapAnalysisLoading] = useState(false);
  const [search, setSearch] = useState("");

  const gapAnalysisToDisplay = gapAnalysis.filter(
    (gapAnalysis) =>
      gapAnalysis.name.toLowerCase().includes(search.toLowerCase()) ||
      search === ""
  );

  useEffect(() => {
    setGapAnalysisLoading(true);
    getGapAnalyses(authInfo.accessToken ?? null).then((gapAnalysis) => {
      if (gapAnalysis) {
        setGapAnalysis(gapAnalysis);
      } else {
        toast.error("Failed to fetch gap analyses");
      }
      setGapAnalysisLoading(false);
    });
  }, []);

  return (
    <Layout pageName="Gap Analysis">
      <div className="flex justify-center items-center space-x-2">
        <NewGapAnalysisDialog
          setGapAnalysis={setGapAnalysis}
          hideAtlasWidget={true}
        />
        <Input
          className="w-[560px]"
          placeholder="Search gap analysis"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="flex justify-center space-y-2">
        {gapAnalysisLoading ? (
          <LoadingView customText="Loading gap analysis..." />
        ) : (
          <div className="flex flex-col space-y-2">
            {gapAnalysisToDisplay
              .sort((a, b) => {
                return (
                  new Date(b.updated_at).getTime() -
                  new Date(a.updated_at).getTime()
                );
              })
              .map((gapAnalysis) => (
                <a
                  href={`/gap-analysis/${gapAnalysis.id}`}
                  key={gapAnalysis.id}
                >
                  <div className="bg-white w-[720px] p-5 rounded-md hover:bg-gray-200 cursor-pointer">
                    <div className="flex items-center justify-between">
                      <div className="text-md font-bold">
                        {gapAnalysis.name}
                      </div>
                      <TimeAgo timestamp={gapAnalysis.updated_at} />
                    </div>
                  </div>
                </a>
              ))}
          </div>
        )}
        {gapAnalysisToDisplay.length === 0 && !gapAnalysisLoading && (
          <div className="text-center text-gray-500">No gap analysis found</div>
        )}
      </div>
    </Layout>
  );
};
