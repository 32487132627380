import React, { forwardRef } from "react";
import { SearchResult } from "../types";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../shadcn/components/card";
import { cn } from "../shadcn/lib/utils";
import { Badge } from "../shadcn/components/badge";
import { Button } from "../shadcn/components/button";
import { Copy } from "lucide-react";
import { toast } from "sonner";

export const processRelevantQuote = (
  text: string,
  startIndex: number,
  endIndex: number
) => {
  const wordCount = 5;
  const textFmt = text.replace(//g, "•");
  const words = textFmt.split(/\s+/);

  const startWordIndex = textFmt.slice(0, startIndex).split(/\s+/).length - 1;
  const endWordIndex = textFmt.slice(0, endIndex).split(/\s+/).length;

  const startRange = Math.max(startWordIndex - wordCount, 0);
  const endRange = Math.min(endWordIndex + wordCount, words.length);

  const quote = words.slice(startWordIndex, endWordIndex).join(" ");
  const beforeQuote = words.slice(startRange, startWordIndex).join(" ");
  const afterQuote = words.slice(endWordIndex, endRange).join(" ");

  return {
    quote,
    beforeQuote,
    afterQuote,
  };
};

const HighlightedSection = (props: {
  text: string;
  startIndex: number;
  endIndex: number;
}) => {
  const { quote, beforeQuote, afterQuote } = processRelevantQuote(
    props.text,
    props.startIndex,
    props.endIndex
  );
  return (
    <CardContent className="text-md text-gray-600">
      ...
      {beforeQuote} <span className="bg-yellow-200">{quote}</span> {afterQuote}
      ...
    </CardContent>
  );
};

interface SearchResultCardProps {
  searchResult: SearchResult;
  activeSearchDocId: string | null;
  setActiveSearchDocId: (searchDocId: string | null) => void;
  clickCallback?: () => void;
}

export const SearchResultCard = forwardRef<
  HTMLDivElement,
  SearchResultCardProps
>(
  (
    { searchResult, activeSearchDocId, setActiveSearchDocId, clickCallback },
    ref
  ) => {
    const handleCopy = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      const quote = searchResult.relevant_quote
        ? processRelevantQuote(
            searchResult.text,
            searchResult.relevant_quote.start_index,
            searchResult.relevant_quote.end_index
          ).quote
        : searchResult.text;
      navigator.clipboard.writeText(
        `${searchResult.doc_name} - Page ${searchResult.page}
        
"${quote}"`
      );
      toast.success("Copied to clipboard");
    };

    return (
      <Card
        className={cn(
          "hover:shadow-lg hover:border-2 hover:border-gray-500 rounded-lg cursor-pointer relative",
          activeSearchDocId === searchResult.search_doc_id
            ? "border-2 border-gray-500"
            : ""
        )}
        onClick={() => {
          setActiveSearchDocId(searchResult.search_doc_id);
          if (clickCallback) {
            clickCallback();
          }
        }}
        ref={ref}
      >
        <Button
          variant="ghost"
          size="icon"
          className="absolute top-2 right-2 z-10"
          onClick={handleCopy}
        >
          <Copy className="h-4 w-4" />
        </Button>
        <CardHeader className="space-y-4">
          <CardTitle>
            <div className="font-normal" style={{ lineHeight: "1.2" }}>
              <span className="inline break-words align-middle">
                {decodeURI(searchResult.doc_name)}
              </span>
              <Badge className="bg-gray-400 inline ml-4 text-sm">
                {searchResult.doc_type.short_display_name ||
                  searchResult.doc_type.name}
              </Badge>
            </div>
          </CardTitle>
          {searchResult.page !== null && (
            <CardDescription className="flex text-gray-400">
              {searchResult.additional_info && (
                <span className="mr-2">{searchResult.additional_info}</span>
              )}
              <span>Page {searchResult.page}</span>
            </CardDescription>
          )}
        </CardHeader>
        {searchResult.relevant_quote !== null && (
          <HighlightedSection
            text={searchResult.text}
            startIndex={searchResult.relevant_quote.start_index}
            endIndex={searchResult.relevant_quote.end_index}
          />
        )}
      </Card>
    );
  }
);
