import React from "react";
import { DataTable, HeaderCell } from "../../components/Table";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { Badge } from "../../shadcn/components/badge";
import { Switch } from "../../shadcn/components/switch";
import { cn } from "../../shadcn/lib/utils";

export const itemSearchKey = (item: Record<string, any>) => {
  const key = ((item["section"] ?? "") + " " + (item["language"] ?? ""))
    .trim()
    .toLowerCase();
  return key;
};

export const getRelatedReferenceData = (
  relatedData: Record<string, any>[],
  referenceType: string
) => {
  const relatedReferenceData = relatedData.filter((item: any) => {
    switch (referenceType) {
      case "NCQA HPA 2024":
        return item["source"] === "NCQA HPA 2024";
      case "KKA":
        return item["source"] === "H&S Code" || item["source"] === "28 CCR";
      default:
        return (
          item["source"] !== "NCQA HPA 2024" &&
          item["source"] !== "H&S Code" &&
          item["source"] !== "28 CCR"
        );
    }
  });
  return relatedReferenceData;
};

const ReferenceDisplay = (props: {
  relatedItems: Record<string, any>[];
  searchText: string;
}) => {
  return (
    <div className="flex gap-1 flex-wrap">
      {props.relatedItems.map((item) => (
        <Tooltip key={item["section"]}>
          <TooltipTrigger>
            <Badge
              variant="default"
              className={cn(
                "text-xs",
                props.searchText &&
                  itemSearchKey(item).includes(
                    props.searchText.toLowerCase()
                  ) &&
                  "bg-yellow-400 text-black"
              )}
            >
              {item["section"]}
            </Badge>
          </TooltipTrigger>
          <TooltipContent className="max-w-[400px] max-h-[400px] overflow-y-auto">
            {item["language"] ?? ""}
          </TooltipContent>
        </Tooltip>
      ))}
    </div>
  );
};

const FullTextDisplay = (props: {
  relatedItems: Record<string, any>[];
  searchText: string;
}) => {
  const highlightText = (text: string, searchText: string) => {
    if (!searchText) return text;
    const parts = text.split(new RegExp(`(${searchText})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === searchText.toLowerCase() ? (
        <span key={index} className="bg-yellow-200">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <div className="space-y-2">
      {props.relatedItems.map((record, index) => {
        return (
          <div key={index} className="space-y-1">
            <div className="font-semibold">
              {highlightText(record.section ?? "", props.searchText)}
            </div>
            {record.section_title && (
              <div className="text-gray-500 font-semibold">
                {record.section_title}
              </div>
            )}
            {record.subsection_title && (
              <div className="italic">{record.subsection_title}</div>
            )}
            <div className="text-gray-500 whitespace-pre-wrap">
              {highlightText(record.language ?? "", props.searchText)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const SourceDisplay = (props: {
  showText: boolean;
  relatedItems: Record<string, any>[];
  searchText: string;
}) => {
  return props.showText ? (
    <FullTextDisplay
      relatedItems={props.relatedItems}
      searchText={props.searchText}
    />
  ) : (
    <ReferenceDisplay
      relatedItems={props.relatedItems}
      searchText={props.searchText}
    />
  );
};

export const CrosswalkTable = (props: {
  data: Record<string, any>[];
  children: React.ReactNode;
  setActiveReference: React.Dispatch<
    React.SetStateAction<{
      dhcs_key: string;
    } | null>
  >;
  activeReference: {
    dhcs_key: string;
  } | null;
  searchText: string;
}) => {
  let columns: any[] = [
    {
      header: ({ column }: any) => {
        return <HeaderCell column={column} columnName="Show Text" />;
      },
      cell: ({ row }: any) => {
        const rowId = row.original["dhcs_contract"]["section"];
        return (
          <Switch
            id="full-text-toggle"
            checked={props.activeReference?.dhcs_key === rowId}
            onCheckedChange={(checked) =>
              props.setActiveReference({
                dhcs_key: checked ? rowId : null,
              })
            }
          />
        );
      },
      id: "show-text",
    },
    {
      header: ({ column }: any) => {
        return <HeaderCell column={column} columnName="DHCS Contract" />;
      },
      cell: ({ row }: any) => {
        const rowId = row.original["dhcs_contract"]["section"];
        const relatedData = [row.original["dhcs_contract"]];
        return (
          <SourceDisplay
            showText={props.activeReference?.dhcs_key === rowId}
            relatedItems={relatedData}
            searchText={props.searchText}
          />
        );
      },
      id: "dhcs-contract",
    },
    {
      header: ({ column }: any) => {
        return <HeaderCell column={column} columnName="KKA" />;
      },
      cell: ({ row }: any) => {
        const rowId = row.original["dhcs_contract"]["section"];
        const relatedData = getRelatedReferenceData(
          row.original.related,
          "KKA"
        );
        return (
          <SourceDisplay
            showText={props.activeReference?.dhcs_key === rowId}
            relatedItems={relatedData}
            searchText={props.searchText}
          />
        );
      },
      id: "kka",
    },
    {
      header: ({ column }: any) => {
        return <HeaderCell column={column} columnName="NCQA" />;
      },
      cell: ({ row }: any) => {
        const rowId = row.original["dhcs_contract"]["section"];
        const relatedData = getRelatedReferenceData(
          row.original.related,
          "NCQA HPA 2024"
        );
        return (
          <SourceDisplay
            showText={props.activeReference?.dhcs_key === rowId}
            relatedItems={relatedData}
            searchText={props.searchText}
          />
        );
      },
      id: "ncqa",
    },
    {
      header: ({ column }: any) => {
        return <HeaderCell column={column} columnName="Others" />;
      },
      cell: ({ row }: any) => {
        const rowId = row.original["dhcs_contract"]["section"];
        const relatedData = getRelatedReferenceData(
          row.original.related,
          "Others"
        );
        return (
          <SourceDisplay
            showText={props.activeReference?.dhcs_key === rowId}
            relatedItems={relatedData}
            searchText={props.searchText}
          />
        );
      },
      id: "others",
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={props.data}
      paginationAtTop={true}
      headerChildren={props.children}
      paginationParams={{ pageSize: 10, pageIndex: 0 }}
      totalRecordCount={props.data.length}
      tableWrapperClassName="overflow-y-auto h-[calc(100vh-180px)]"
      tableCellClassName="align-top"
    />
  );
};
