import { useAuthInfo } from "@propelauth/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { getCrosswalkData } from "../../utils/apiCalls";
import { toast } from "sonner";
import { Layout } from "../../components/Layout";
import { LoadingView } from "../../components/Loading";
import { CrosswalkTable, itemSearchKey } from "./CrosswalkTable";
import { Search } from "../../shadcn/components/search";
import { getFilterCounts, SelectFilter } from "../../components/FilterUtils";

const SearchLanguageText = (props: {
  setSearchTerm: (searchTerm: string) => void;
  width: string;
}) => {
  const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const onSearchChange = useCallback(
    (e: any) => {
      const value = e.target.value.trimEnd();

      // Clear the previous timeout
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }

      // Set a new timeout to update the search term after 1 second
      searchTimeoutRef.current = setTimeout(() => {
        props.setSearchTerm(value);
      }, 300);
    },
    [props.setSearchTerm]
  );

  return (
    <Search
      onChangeHandler={onSearchChange}
      width={props.width}
      placeholder="Search..."
    />
  );
};

export const CrosswalkView = () => {
  const authInfo = useAuthInfo();
  const [dataLoading, setDataLoading] = useState(true);
  const [data, setData] = useState<Record<string, any>[]>([]);
  const [activeReference, setActiveReference] = useState<{
    dhcs_key: string;
  } | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const [sectionFilter, setSectionFilter] = useState<string[]>([]);
  const [subsectionFilter, setSubsectionFilter] = useState<string[]>([]);

  // filter for regulator
  const searchTextData = data.filter(
    (item) =>
      itemSearchKey(item["dhcs_contract"]).includes(searchText.toLowerCase()) ||
      item["related"].some((relatedItem: any) =>
        itemSearchKey(relatedItem).includes(searchText.toLowerCase())
      ) ||
      searchText === ""
  );

  const sectionData = searchTextData.filter(
    (item) =>
      sectionFilter.includes(item["dhcs_contract"]["section_title"]) ||
      sectionFilter.length === 0
  );

  const subsectionData = sectionData.filter(
    (item) =>
      subsectionFilter.includes(item["dhcs_contract"]["subsection_title"]) ||
      subsectionFilter.length === 0
  );

  useEffect(() => {
    setDataLoading(true);
    getCrosswalkData(authInfo.accessToken ?? null).then((response) => {
      if (response !== null) {
        setData(response);
      } else {
        toast.error("Failed to fetch crosswalk data");
      }
      setDataLoading(false);
    });
  }, []);

  const sectionFilterCounts = getFilterCounts(
    searchTextData.map((item) => item["dhcs_contract"]["section_title"])
  );

  const subsectionFilterCounts = getFilterCounts(
    sectionData.map((item) => item["dhcs_contract"]["subsection_title"])
  );

  return (
    <Layout pageName="Crosswalk">
      {!dataLoading && (
        <CrosswalkTable
          data={subsectionData.sort((a, b) => {
            return a["dhcs_contract"]["section"].localeCompare(
              b["dhcs_contract"]["section"]
            );
          })}
          activeReference={activeReference}
          setActiveReference={setActiveReference}
          searchText={searchText}
        >
          <div className="flex items-center justify-end space-x-2">
            <SearchLanguageText setSearchTerm={setSearchText} width="300px" />
            <SelectFilter
              title="Section"
              filterCounts={sectionFilterCounts}
              activeFilter={sectionFilter}
              setActiveFilter={setSectionFilter}
            />
            <SelectFilter
              title="Subsection"
              filterCounts={subsectionFilterCounts}
              activeFilter={subsectionFilter}
              setActiveFilter={setSubsectionFilter}
            />
          </div>
        </CrosswalkTable>
      )}
      {dataLoading && <LoadingView customText="Loading crosswalk data..." />}
    </Layout>
  );
};
