import { useContext } from "react";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import { Separator } from "../../shadcn/components/separator";
import {
  ClearHighlightTooltipActionButton,
  CopyTooltipActionButton,
  CopyWithCitationTooltipActionButton,
  HighlightTooltipActionButton,
} from "../../components/HighlightTooltipActionButton";

export const ChatHighlightActions = (props: {
  citationId: string;
  setMessage: (
    message: string,
    pageNumber: number,
    docHighlight: boolean
  ) => void;
  onClickCallback?: () => void;
}) => {
  const { docToView, citations, docMetadata, pageNumber } =
    useContext(DocViewerContext);
  return (
    <div className="w-48">
      <HighlightTooltipActionButton
        text="Clarify"
        onClick={async () => {
          if (citations.length && citations[0]?.match && docToView?.docId) {
            props.onClickCallback?.();
            props.setMessage(
              `<messageType>clarify</messageType><relevantText>${citations[0].match}</relevantText>`,
              pageNumber,
              true
            );
          }
        }}
        explanationContent="Clarify the highlighted section in simple language"
      />
      <HighlightTooltipActionButton
        text="Define"
        onClick={async () => {
          if (citations.length && citations[0]?.match && docToView?.docId) {
            props.onClickCallback?.();
            props.setMessage(
              `<messageType>definition</messageType><relevantText>${citations[0].match}</relevantText>`,
              pageNumber,
              true
            );
          }
        }}
        explanationContent="Define of the highlighted term in the document"
      />
      <HighlightTooltipActionButton
        text="Impacted Policies"
        onClick={async () => {
          if (citations.length && citations[0]?.match && docToView?.docId) {
            props.onClickCallback?.();
            props.setMessage(
              `<messageType>impacted_policies</messageType><relevantText>${citations[0].match}</relevantText>`,
              pageNumber,
              true
            );
          }
        }}
        explanationContent="Find the policies impacted by the highlighted section"
      />
      <HighlightTooltipActionButton
        text="Reference Document"
        onClick={async () => {
          if (citations.length && citations[0]?.match && docToView?.docId) {
            props.onClickCallback?.();
            props.setMessage(
              `<messageType>linked_document</messageType><relevantText>${citations[0].match}</relevantText>`,
              pageNumber,
              true
            );
          }
        }}
        explanationContent="Find the relevant sections in the highlighted documents"
      />
      <Separator />
      {citations.length && citations[0]?.match && (
        <>
          <CopyTooltipActionButton citationText={citations[0].match} />
          <CopyWithCitationTooltipActionButton
            docName={docMetadata?.name ?? ""}
            pageNumber={pageNumber}
            citationText={citations[0].match}
          />
        </>
      )}
      <ClearHighlightTooltipActionButton citationId={props.citationId} />
    </div>
  );
};
