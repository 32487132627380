import { useAuthInfo } from "@propelauth/react";
import {
  ActionItem,
  Citation,
  Department,
  DocType,
  Requirement,
  SearchDocName,
} from "../../types";
import { useContext, useEffect, useState } from "react";
import {
  debounce,
  getRelevantDocs,
  updateActionItemReferenceDocTypes,
} from "../../utils/apiCalls";
import { CitationView } from "../Citation/Citations";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { toast } from "sonner";
import { MultiSelectControl } from "../MultiSelectControl";
import { UserContext } from "../../contexts/UserContext";
import { PdfCitation } from "../PdfViewer/PdfHighlighter/types";

const SelectDataSources = (props: {
  docId: string;
  requirementId: string;
  actionItemId?: string;
  setActionItem: React.Dispatch<React.SetStateAction<ActionItem | null>>;
  actionItem: ActionItem | null;
  docTypeAttr: "reference_doc_types" | "impacted_doc_types";
  allowedDocTypes: DocType[];
}) => {
  const authInfo = useAuthInfo();

  const updateDocTypes = debounce((docTypeIds: string[]) => {
    if (props.actionItemId && props.actionItem) {
      updateActionItemReferenceDocTypes(
        props.docId,
        props.requirementId,
        props.actionItemId,
        docTypeIds,
        authInfo.accessToken ?? null
      );
    }
  }, 1000);

  return (
    <MultiSelectControl
      title="Data Sources"
      items={props.allowedDocTypes.map((docType) => ({
        id: docType.id,
        name: docType.name,
      }))}
      selectedItems={
        props.actionItem?.[props.docTypeAttr]?.map((docTypeId) => ({
          id: docTypeId,
          name:
            props.allowedDocTypes.find((docType) => docType.id === docTypeId)
              ?.name ?? "",
        })) ?? []
      }
      clearSelectedItems={() => {
        props.setActionItem((prev) => {
          if (prev) {
            return {
              ...prev,
              [props.docTypeAttr]: [],
            };
          }
          return prev;
        });
        updateDocTypes([]);
      }}
      selectItem={(item, isSelected) => {
        let newDocTypes: string[] = [];
        props.setActionItem((prev) => {
          if (prev) {
            newDocTypes = prev[props.docTypeAttr] ?? [];
            if (isSelected) {
              newDocTypes = [...newDocTypes, item.id];
            } else {
              newDocTypes = newDocTypes.filter((n) => n !== item.id);
            }
            return {
              ...prev,
              [props.docTypeAttr]: newDocTypes,
            };
          }
          return prev;
        });
        updateDocTypes(newDocTypes);
      }}
      selectAll={() => {
        const newDocTypes = props.allowedDocTypes.map((docType) => docType.id);
        props.setActionItem((prev) => {
          if (prev) {
            return {
              ...prev,
              [props.docTypeAttr]: newDocTypes,
            };
          }
          return prev;
        });
        updateDocTypes(newDocTypes);
      }}
      selectItemOnly={(item) => {
        props.setActionItem((prev) => {
          if (prev) {
            return {
              ...prev,
              [props.docTypeAttr]: [item.id],
            };
          }
          return prev;
        });
        updateDocTypes([item.id]);
      }}
    />
  );
};

const ActionItemDocumentsView = (props: {
  docId: string;
  requirement: Requirement;
  actionItemId?: string;
  actionItem: ActionItem | null;
  setActionItem: React.Dispatch<React.SetStateAction<ActionItem | null>>;
  activeCitationId: string | null;
  setActiveCitationId: React.Dispatch<React.SetStateAction<string | null>>;
  setDoc: React.Dispatch<React.SetStateAction<SearchDocName | null>>;
  allowedDocTypes: DocType[];
  hideAtlasWidget?: boolean;
  documentAttr: "citations" | "reference_documents";
  title: string;
  citationType: "impacted-document" | "reference-document";
  docTypeAttr: "reference_doc_types" | "impacted_doc_types";
  enableDocTypeSelection?: boolean;
  highlightedCitation: PdfCitation | null;
  setHighlightedCitation: React.Dispatch<
    React.SetStateAction<PdfCitation | null>
  >;
}) => {
  const authInfo = useAuthInfo();
  const [relevantDocs, setRelevantDocs] = useState<SearchDocName[]>([]);
  const persistCitations =
    props.actionItemId !== undefined && props.actionItemId !== "";
  const urlSuffix = `${props.docId}/${props.requirement.id}`;
  const allowedDocTypeIds =
    props.actionItem?.[props.docTypeAttr] ||
    props.allowedDocTypes.map((docType) => docType.id);

  const onGenerateCitations = (
    citations: Citation[],
    departments: Department[]
  ) => {
    props.setActionItem((prev) => {
      if (prev) {
        let departmentsToAdd: Department[] = [];
        if (departments.length > 0) {
          departmentsToAdd = departments.filter(
            (department) =>
              !prev.assignees.map((a) => a.id).includes(department.id)
          );
        }
        return {
          ...prev,
          assignees: [...prev.assignees, ...departmentsToAdd],
          [props.documentAttr]: [
            ...prev[props.documentAttr].filter(
              (existingCitation) =>
                !citations.some(
                  (newCitation) => newCitation.text === existingCitation.text
                )
            ),
            ...citations,
          ],
        };
      }
      return prev;
    });
  };

  const onSuccess = (citation: Citation, existingCitation: Citation | null) => {
    props.setActionItem((prev) => {
      if (prev) {
        let newCitations = [...prev[props.documentAttr]];
        if (existingCitation) {
          newCitations = newCitations.filter(
            (citation) => citation.id !== existingCitation!.id
          );
        }
        return {
          ...prev,
          [props.documentAttr]: [...newCitations, citation],
        };
      }
      return prev;
    });
  };

  useEffect(() => {
    if (props.requirement.id) {
      getRelevantDocs(
        props.docId,
        props.requirement.id,
        allowedDocTypeIds,
        props.citationType,
        authInfo.accessToken ?? null
      ).then((response) => {
        if (response !== null) {
          setRelevantDocs(response);
        } else {
          toast.error("Unable to get relevant documents");
        }
      });
    }
  }, [props.requirement.id]);

  return (
    <CitationView
      docTypeSelector={
        props.enableDocTypeSelection ? (
          <SelectDataSources
            docId={props.docId}
            requirementId={props.requirement.id!}
            actionItemId={props.actionItemId}
            setActionItem={props.setActionItem}
            actionItem={props.actionItem}
            docTypeAttr={props.docTypeAttr}
            allowedDocTypes={props.allowedDocTypes}
          />
        ) : undefined
      }
      highlightedCitation={props.highlightedCitation}
      setHighlightedCitation={props.setHighlightedCitation}
      persistUrl={
        persistCitations
          ? {
              prefix: `gap-analysis/action-item/${props.citationType}`,
              suffix: `${urlSuffix}/${props.actionItemId}`,
            }
          : undefined
      }
      generateUrl={{
        prefix: `gap-analysis/action-item/${props.citationType}`,
        suffix: `${urlSuffix}/${props.actionItemId}`,
      }}
      relevantDocs={relevantDocs}
      allowedDocTypeIds={allowedDocTypeIds}
      question={props.requirement.text}
      citations={props.actionItem?.[props.documentAttr] ?? []}
      activeCitationId={props.activeCitationId}
      setActiveCitationId={props.setActiveCitationId}
      onNewCitationSuccess={onSuccess}
      headerChildren={
        <Tooltip>
          <TooltipTrigger>
            {props.requirement.text.slice(0, 100)}
            {props.requirement.text.length > 100 && "..."}
          </TooltipTrigger>
          <TooltipContent className="max-w-[300px]">
            {props.requirement.text}
          </TooltipContent>
        </Tooltip>
      }
      onDeleteCitationSuccess={(citation: Citation) => {
        props.setActionItem((prev) => {
          if (prev) {
            return {
              ...prev,
              [props.documentAttr]: prev[props.documentAttr].filter(
                (c) => c.id !== citation.id
              ),
            };
          }
          return prev;
        });
      }}
      labelText={props.title}
      setDoc={(ac) => {
        if (ac === null) {
          props.setDoc(null);
        } else {
          props.setDoc({
            id: null,
            doc_id: ac.doc_id!,
            name: ac.doc_name!,
            doc_type_name: "",
            additional_metadata: {},
            result_type: "filename",
            citation: null,
          });
        }
      }}
      onClickGenerate={onGenerateCitations}
      hideAtlasWidget={props.hideAtlasWidget}
    />
  );
};

const ActionItemImpactedDocumentsView = (props: {
  docId: string;
  requirement: Requirement;
  actionItemId?: string;
  actionItem: ActionItem | null;
  setActionItem: React.Dispatch<React.SetStateAction<ActionItem | null>>;
  activeCitationId: string | null;
  setActiveCitationId: React.Dispatch<React.SetStateAction<string | null>>;
  setDoc: React.Dispatch<React.SetStateAction<SearchDocName | null>>;
  hideAtlasWidget?: boolean;
  highlightedCitation: PdfCitation | null;
  setHighlightedCitation: React.Dispatch<
    React.SetStateAction<PdfCitation | null>
  >;
}) => {
  const { regDocGapDocTypes } = useContext(UserContext);

  return (
    <ActionItemDocumentsView
      docId={props.docId}
      requirement={props.requirement}
      actionItemId={props.actionItemId}
      actionItem={props.actionItem}
      setActionItem={props.setActionItem}
      activeCitationId={props.activeCitationId}
      setActiveCitationId={props.setActiveCitationId}
      setDoc={props.setDoc}
      allowedDocTypes={regDocGapDocTypes}
      hideAtlasWidget={props.hideAtlasWidget}
      highlightedCitation={props.highlightedCitation}
      setHighlightedCitation={props.setHighlightedCitation}
      documentAttr="citations"
      docTypeAttr="impacted_doc_types"
      citationType="impacted-document"
      title="Impacted Documents"
      enableDocTypeSelection={false}
    />
  );
};

const ActionItemReferenceDocumentsView = (props: {
  docId: string;
  requirement: Requirement;
  actionItemId?: string;
  actionItem: ActionItem | null;
  setActionItem: React.Dispatch<React.SetStateAction<ActionItem | null>>;
  activeCitationId: string | null;
  setActiveCitationId: React.Dispatch<React.SetStateAction<string | null>>;
  setDoc: React.Dispatch<React.SetStateAction<SearchDocName | null>>;
  hideAtlasWidget?: boolean;
  highlightedCitation: PdfCitation | null;
  setHighlightedCitation: React.Dispatch<
    React.SetStateAction<PdfCitation | null>
  >;
}) => {
  const { externalDocTypes } = useContext(UserContext);

  return (
    <ActionItemDocumentsView
      docId={props.docId}
      requirement={props.requirement}
      actionItemId={props.actionItemId}
      actionItem={props.actionItem}
      setActionItem={props.setActionItem}
      activeCitationId={props.activeCitationId}
      setActiveCitationId={props.setActiveCitationId}
      setDoc={props.setDoc}
      allowedDocTypes={externalDocTypes}
      hideAtlasWidget={props.hideAtlasWidget}
      highlightedCitation={props.highlightedCitation}
      setHighlightedCitation={props.setHighlightedCitation}
      documentAttr="reference_documents"
      docTypeAttr="reference_doc_types"
      citationType="reference-document"
      title="Reference Documents"
      enableDocTypeSelection={true}
    />
  );
};

export const ActionItemFullDocumentsView = (props: {
  docId: string;
  requirement: Requirement;
  actionItemId?: string;
  setActionItem: React.Dispatch<React.SetStateAction<ActionItem | null>>;
  activeCitationId: string | null;
  setActiveCitationId: React.Dispatch<React.SetStateAction<string | null>>;
  setDoc: React.Dispatch<React.SetStateAction<SearchDocName | null>>;
  actionItem: ActionItem | null;
  hideAtlasWidget?: boolean;
  highlightedCitation: PdfCitation | null;
  setHighlightedCitation: React.Dispatch<
    React.SetStateAction<PdfCitation | null>
  >;
}) => {
  return (
    <div>
      <ActionItemImpactedDocumentsView
        docId={props.docId}
        requirement={props.requirement}
        actionItemId={props.actionItemId}
        setActionItem={props.setActionItem}
        activeCitationId={props.activeCitationId}
        setActiveCitationId={props.setActiveCitationId}
        setDoc={props.setDoc}
        actionItem={props.actionItem}
        hideAtlasWidget={props.hideAtlasWidget}
        highlightedCitation={props.highlightedCitation}
        setHighlightedCitation={props.setHighlightedCitation}
      />

      <ActionItemReferenceDocumentsView
        docId={props.docId}
        requirement={props.requirement}
        actionItemId={props.actionItemId}
        setActionItem={props.setActionItem}
        activeCitationId={props.activeCitationId}
        setActiveCitationId={props.setActiveCitationId}
        setDoc={props.setDoc}
        actionItem={props.actionItem}
        hideAtlasWidget={props.hideAtlasWidget}
        highlightedCitation={props.highlightedCitation}
        setHighlightedCitation={props.setHighlightedCitation}
      />
    </div>
  );
};
