import React from "react";
import { DataTable, HeaderCell } from "../../../components/Table";
import { Button } from "../../../shadcn/components/button";
import { StatusSelector } from "../../../components/StatusSelector";
import { TruncatedWTooltip } from "../../../components/FilterUtils";

export const DashboardTable = (props: {
  data: Record<string, any>[];
  setData: React.Dispatch<React.SetStateAction<Record<string, any>[]>>;
  setActiveRow: (row: Record<string, any>) => void;
  children: React.ReactNode;
}) => {
  let columns: any[] = [
    {
      header: <span></span>,
      id: "view-button",
      cell: ({ row }: any) => {
        return (
          <Button
            variant="ghost"
            className="text-blue-500 underline"
            onClick={() => props.setActiveRow(row.original)}
          >
            View
          </Button>
        );
      },
    },
    {
      header: ({ column }: any) => {
        return <HeaderCell column={column} columnName="Status" />;
      },
      cell: ({ row }: any) => {
        return (
          <StatusSelector
            hideLabel={true}
            options={[
              {
                value: "Non-Compliant",
                label: "Non-Compliant",
                className:
                  "data-[state=on]:bg-red-400 data-[state=off]:hover:bg-red-100",
              },

              {
                value: "Compliant",
                label: "Compliant",
                className:
                  "data-[state=on]:bg-green-400 data-[state=off]:hover:bg-green-100",
              },
            ]}
            status={row.original.status}
            handleStatusChange={(status: string) => {
              props.setData((prevData) =>
                prevData.map((item) => {
                  if (item.id === row.original.id) {
                    return { ...item, status: status };
                  }
                  return item;
                })
              );
              return Promise.resolve();
            }}
          />
        );
      },
      id: "status-badge",
    },
    {
      header: ({ column }: any) => {
        return <HeaderCell column={column} columnName="Requirement" />;
      },
      cell: ({ row }: any) => {
        return <TruncatedWTooltip text={row.original.requirement} />;
      },
      accessorKey: "requirement",
    },
    {
      header: ({ column }: any) => {
        return <HeaderCell column={column} columnName="Document" />;
      },
      accessorKey: "document",
    },
    {
      header: ({ column }: any) => {
        return <HeaderCell column={column} columnName="Departments" />;
      },
      cell: ({ row }: any) => {
        return <div>{row.original.departments.join(", ")}</div>;
      },
      accessorKey: "departments",
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={props.data}
      paginationAtTop={true}
      headerChildren={props.children}
      paginationParams={{ pageSize: 50, pageIndex: 0 }}
      totalRecordCount={props.data.length}
    />
  );
};
