import { useAuthInfo } from "@propelauth/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { downloadInsight, getInsight } from "../../utils/apiCalls";
import { DataTable, HeaderCell } from "../../components/Table";
import { Layout } from "../../components/Layout";
import { Insight } from "../../types";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../shadcn/components/breadcrumb";
import { LoadingView } from "../../components/Loading";
import { Search } from "../../shadcn/components/search";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { Button } from "../../shadcn/components/button";
import { DownloadIcon } from "@radix-ui/react-icons";

const BreadcrumbNav = () => {
  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink href="/insight">Insights</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage>Table</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );
};

const SearchReport = (props: {
  setSearchTerm: (searchTerm: string) => void;
  width: string;
}) => {
  const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const onSearchChange = useCallback(
    (e: any) => {
      const value = e.target.value.trimEnd();

      // Clear the previous timeout
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }

      // Set a new timeout to update the search term after 1 second
      searchTimeoutRef.current = setTimeout(() => {
        props.setSearchTerm(value);
      }, 300);
    },
    [props.setSearchTerm]
  );

  return (
    <Search
      onChangeHandler={onSearchChange}
      width={props.width}
      placeholder="Search..."
    />
  );
};

export const InsightTableView = () => {
  const { insightId } = useParams();
  const authInfo = useAuthInfo();
  const [data, setData] = useState<any[]>([]);
  const [insight, setInsight] = useState<Insight>();
  const [dataLoading, setDataLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const filteredData = data.filter((item) => {
    return Object.values(item).some(
      (value: any) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        searchTerm === ""
    );
  });

  useEffect(() => {
    if (insightId) {
      setDataLoading(true);
      downloadInsight(insightId, authInfo.accessToken ?? null).then((data) => {
        if (data !== null) {
          setData(data);
        } else {
          toast.error("Failed to download insight");
        }
        setDataLoading(false);
      });
      getInsight(insightId, authInfo.accessToken ?? null).then((data) => {
        if (data !== null) {
          setInsight(data);
        } else {
          toast.error("Failed to get insight");
        }
      });
    }
  }, [insightId]);

  let columns: any[] = [];
  if (data.length > 0) {
    columns = Object.keys(data[0]).map((columnName: string) => {
      return {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName={columnName} />;
        },
        accessorKey: columnName,
        cell: ({ cell }: any) => {
          const statement = cell.getValue();
          if (
            searchTerm &&
            statement.toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            // highlight search term
            const regex = new RegExp(`(${searchTerm})`, "gi");
            const parts = statement.split(regex);
            return (
              <span>
                {parts.map((part: string, index: number) => (
                  <span
                    key={index}
                    className={`${
                      part.toLowerCase() === searchTerm.toLowerCase()
                        ? "bg-yellow-500"
                        : ""
                    }`}
                  >
                    {part}
                  </span>
                ))}
              </span>
            );
          }
          return <span>{statement}</span>;
        },
      };
    });
  }

  const downloadCSV = () => {
    const csvRows = [];
    const headers = columns.map((col) => col.accessorKey).join(",");
    csvRows.push(headers);

    filteredData.forEach((row) => {
      const values = columns
        .map((col) => {
          const escaped = ("" + row[col.accessorKey]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        })
        .join(",");
      csvRows.push(values);
    });

    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", `${insight?.name}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Layout pageName="Insights Table">
      <BreadcrumbNav />
      <div className="space-y-2">
        <div className="text-2xl font-bold">{insight?.name}</div>
        <div className="flex items-center justify-between">
          <div className="space-x-2 flex items-center">
            <SearchReport setSearchTerm={setSearchTerm} width="300px" />
            <div className="text-sm text-gray-500">{`Record Count: ${filteredData.length}`}</div>
          </div>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button variant="outline" size="icon" onClick={downloadCSV}>
                <DownloadIcon className="w-4 h-4" />
                <span className="sr-only">Download Document</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent>Download Document</TooltipContent>
          </Tooltip>
        </div>
      </div>
      {dataLoading && <LoadingView />}
      {!dataLoading && (
        <DataTable
          columns={columns}
          data={filteredData}
          paginationAtTop={true}
          paginationParams={{ pageSize: 50, pageIndex: 0 }}
          totalRecordCount={filteredData.length}
        />
      )}
    </Layout>
  );
};
